import React, { useEffect, useState, useContext } from "react";
import Plot from "react-plotly.js";
import {
  CUSTOM_HEATMAP_COLORS,
  HEATMAP_INITIAL,
} from "../../constants/constants";
import ToolTip from "../Tooltip/Tooltip";
import styles from "./StateHeatmap.module.css";
import { useDisconnect } from "@web3modal/ethers/react";
import { AuthContext } from "../../AuthWrapper/AuthWrapper";
import { fixToTwoDecimals } from "../../utils/helpers";

function StateHeatmap({ dashboardState }) {
  const [data, setData] = useState(HEATMAP_INITIAL);
  const [heatmapData, setHeatmapData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [models, setModels] = useState([]);
  const [project_ids, setProjectIds] = useState([]);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const { disconnect } = useDisconnect();
  const { state, send } = useContext(AuthContext);
  const [selectedTf, setSelectedTf] = useState("high");
  const [isTimeframeOpen, setIsTimeframeOpen] = useState(false);

  useEffect(() => {
    if (dashboardState) {
      // Get models (cex, gnews, twitter, etc.)
      const allModels = Object.keys(dashboardState);

      // Get project IDs (BTC, ETH, SOL, etc.)
      // Take any model (e.g. first one) and get its keys since all models have same projects
      const allProjectIds = Object.keys(dashboardState[allModels[0]] || {});

      // Sort models by name length (longest to shortest)
      const sortedModels = allModels.sort((a, b) => b.length - a.length);

      setModels(sortedModels);
      setProjectIds(allProjectIds);
    }
  }, [dashboardState]);

  useEffect(() => {
    const fetchData = async () => {
      const result = state.context.modelState;

      // Transform data structure
      const formattedData = {};

      // Iterate through projects
      Object.entries(result).forEach(([projectId, projectData]) => {
        // Iterate through models for each project
        Object.entries(projectData).forEach(([model, modelData]) => {
          // Initialize model object if it doesn't exist
          if (!formattedData[model]) {
            formattedData[model] = {};
          }
          // Add project data under the model
          formattedData[model][projectId] = modelData;
        });
      });

      setData(formattedData);
      processData(formattedData);
    };
    if (
      dashboardState &&
      state.context?.modelState &&
      Object.keys(state.context?.modelState).length > 0
    ) {
      fetchData();
    }
  }, [dashboardState, state.context.modelState, selectedTf]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      processData(data);
    }
  }, [data, models, selectedTf]);

  useEffect(() => {
    const handleResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const processData = (data) => {
    const heatmapValues = models.map((model) =>
      project_ids.map((project) => {
        const modelData = data[project]?.[model];
        if (!modelData) return 0;
        return modelData?.[selectedTf]?.z_residual ?? 0;
      })
    );

    const residualValues = models.map((model) =>
      project_ids.map((project) => {
        const modelData = data[project]?.[model];
        if (!modelData) return 0;
        return modelData?.[selectedTf]?.residual ?? 0;
      })
    );

    setHeatmapData({
      z: heatmapValues,
      x: project_ids,
      y: models,
      type: "heatmap",
      colorscale: CUSTOM_HEATMAP_COLORS,
      showscale: true,
      colorbar: { title: "Z-Score" },
      text: residualValues.map((row) =>
        row.map((value) => `${fixToTwoDecimals(value ?? 0, 2, true)}%`)
      ),
      texttemplate: "%{text}",
      hoverinfo: "text",
      zmin: -5,
      zmax: 5,
    });
  };

  const getPlotDimensions = () => {
    if (dimensions.width < 1415) {
      return { width: "100%", height: "100%" };
    } else if (dimensions.width < 1540) {
      return { width: 500, height: 450 };
    } else if (dimensions.width < 1650) {
      return { width: 540, height: 450 };
    } else {
      return { width: 620, height: 450 };
    }
  };

  const getFontSize = () => {
    if (dimensions.width < 600) {
      return 16;
    } else if (dimensions.width < 1440) {
      return 22;
    } else if (dimensions.width < 1600) {
      return 24;
    } else {
      return 28;
    }
  };
  const fontSize = getFontSize();

  const plotDimensions = getPlotDimensions();

  const toggleTimeframeDropdown = () => {
    setIsTimeframeOpen(!isTimeframeOpen);
  };
  const selectTimeframe = (tf) => {
    send({
      type: "UPDATE_TIMEFRAME",
      payload: tf,
    });
    setSelectedTf(tf);
    setIsTimeframeOpen(false);
  };

  return (
    // <div>
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.headerTextContainer}>
          <div className={styles.headerText}>Heatmap</div>
          <ToolTip id="heatmap" page="home-page" />
        </div>
        <div className={styles.dropdownContainer}>
          <div className={styles.dropdown} onClick={toggleTimeframeDropdown}>
            <span className={styles.selectedOption}>{selectedTf}</span>
            <span className={styles.arrow}>
              {isTimeframeOpen ? (
                <img
                  className={styles.invertedArrow}
                  src="/images/arrowDown.svg"
                  alt="arrow down"
                />
              ) : (
                <img src="/images/arrowDown.svg" alt="arrow down" />
              )}
            </span>
          </div>
          {isTimeframeOpen && (
            <div className={styles.dropdownMenu}>
              {state.context.projectIds.tf.map((tf) => (
                <div
                  key={tf}
                  className={styles.dropdownItem}
                  onClick={() => {
                    selectTimeframe(tf);
                  }}
                >
                  {tf}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>{" "}
      <div className={styles.plotContainer}>
        <Plot
          data={[
            {
              ...heatmapData,
              showscale: dimensions.width > 500, // Show color bar only when width is greater than 1100px
              colorbar: dimensions.width > 500 ? { title: "Z-Score" } : false, // Only add color bar title if the width is greater than 1100px
            },
          ]}
          layout={{
            // width: 600,
            // height: 450,
            width: dimensions.width < 900 ? "" : plotDimensions.width,
            height: dimensions.width < 900 ? "" : plotDimensions.height,
            autosize: true,
            xaxis: {
              title: {
                text: "Asset",
                // standoff: 50, // Add padding below the x-axis title
              },
              color: "#fff",
              showgrid: false, // Remove x-axis grid lines
              zeroline: false, // Remove x-axis zero line
            },
            yaxis: {
              title: {
                text: "Model",
                // standoff: 70, // Add padding to the right of the y-axis title
              },
              color: "#fff",

              showgrid: false, // Remove y-axis grid lines
              zeroline: false, // Remove y-axis zero line
            },
            paper_bgcolor: "rgba(44, 44, 44, 0.0)",
            plot_bgcolor: "rgba(44, 44, 44, 0)",
            font: { color: "#fff" },

            margin: { t: 80, pad: 6 },
          }}
          config={{
            displayModeBar: false,
            staticPlot: true,
            useResizeHandler: true,
            responsive: true,
          }}
          style={{
            width: dimensions.width < 900 ? "100%" : "auto",
            // display: 'flex',

            // justifyContent: 'center',
            height: dimensions.width > 700 ? "auto" : "100%",
          }}
        />
      </div>
    </div>
    // </div>
  );
}

export default StateHeatmap;
