import React, { useMemo } from "react";
import moment from "moment";
import styles from "./DaoComponents.module.css";
import { useDao } from "../../context/DaoContext";
import Loader from "../Loader/Loader";

const SelectedProposal = ({ proposal }) => {
  const { isSelectingProposal, isFetchingProposals, isVoting } = useDao();

  const proposalStatus = useMemo(() => {
    if (!proposal) return null;
    const now = moment();
    const endtime = moment.unix(proposal.end_date);
    if (now.isAfter(endtime)) {
      return "closed";
    }
    return "open";
  }, [proposal]);

  if (isFetchingProposals || isSelectingProposal) {
    return (
      <div className={styles.selectedProposalContainer}>
        <Loader />
      </div>
    );
  }

  if (!proposal) {
    return (
      <div className={styles.selectedProposalContainer}>
        <h3 className={styles.containerTitle}>Selected Proposal</h3>
        <div className={styles.emptyState}>
          Select a proposal to view details
        </div>
      </div>
    );
  }

  return (
    <div className={styles.selectedProposalContainer}>
      {isVoting && <Loader />}
      <div className={styles.proposalHeader}>
        <h3 className={styles.proposalTitle}>{proposal.title}</h3>
        <div
          className={`${styles.proposalStatus} ${
            proposalStatus === "open" ? styles.open : styles.closed
          }`}
        >
          {proposalStatus === "open" ? "Open" : "Closed"}
        </div>
      </div>

      <div className={styles.proposalDescription}>{proposal.description}</div>

      <div className={styles.proposalTimeline}>
        <div className={styles.timelineItem}>
          <span>Started:</span>{" "}
          {moment.unix(proposal.start_date).format("MMM D, YYYY, h:mm A")}
        </div>
        <div className={styles.timelineItem}>
          <span>Ends:</span>{" "}
          {moment.unix(proposal.end_date).format("MMM D, YYYY, h:mm A")}
        </div>
        <div className={styles.timelineItem}>
          <span>Total votes:</span> {proposal.total_votes}
        </div>
      </div>

      <div className={styles.optionsList}>
        <h4>Voting Options</h4>
        {proposal.options.map((option) => {
          const votes = proposal.option_counts[option.id] || 0;
          const percentage = proposal.percentages[option.id] || 0;

          return (
            <div key={option.id} className={styles.optionItem}>
              <div className={styles.optionDetails}>
                <div className={styles.optionName}>{option.text}</div>
                <div className={styles.optionVotes}>
                  {votes} votes ({percentage}%)
                </div>
              </div>
              <div className={styles.progressBarContainer}>
                <div
                  className={styles.progressBar}
                  style={{ width: `${percentage}%` }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectedProposal;
