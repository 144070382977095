import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../AuthWrapper/AuthWrapper";
import { useReferral } from "../AuthWrapper/RefferalWrapper";
import ExpiryProgressBar from "../components/ExpiryProgressbar/Progressbar";
import withAuthenticationGuard from "../components/guards/withAuthWrapper";
import {
  SUBSCRIPTION_ADDRESS,
  SUBSCRIPTION_AMOUNT,
} from "../constants/constants";
import { calculateSubscriptionDays } from "../utils/helpers";
import styles from "./Access.module.css";
import ConnectionOverlay from "../components/withConnectionOverlay/WithConnectionOverlay";
import InsufficientBalanceModal from "../components/InsufficientBalanceModal/InsufficientBalanceModal";
import TextField from "@mui/material/TextField";
import { MinTokenAmountLayout, ENABLE_REFERRAL } from "../constants/constants";
import Loader from "../components/Loader/Loader";
import Tooltip from "../components/Tooltip/Tooltip";
const sumAmounts = (transactions, decimals = 0) => {
  const total =
    transactions?.reduce((sum, transaction) => sum + transaction.amount, 0) ||
    0;
  const rounded = Number(total.toFixed(decimals));
  return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const Api = () => {
  const [subscription, setSubscription] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const { isConnected, address } = useWeb3ModalAccount();
  const { state, send } = useContext(AuthContext);
  const { state: referralState, send: referralSend } = useReferral();
  const [referralCode, setReferralCode] = useState("");
  const [isValidated, setIsValidated] = useState(
    !!referralState?.context?.userAppliedReferralCode?.ref_code
  );

  const isLoading =
    state.matches("idle") ||
    state.matches("signingIn") ||
    state.matches("waitingForSubscription") ||
    state.matches("verifyingTransaction") ||
    state.matches("loggingOut");

  useEffect(() => {
    if (
      state.matches("model") ||
      state.matches("home") ||
      state.matches("strategy") ||
      state.matches("apiKey") ||
      state.matches("chat") ||
      state.matches("dao")
    )
      send({ type: "CHECK_ROUTE" });
  }, [send]);

  useEffect(() => {
    if (state.context?.accessToken) {
      fetchSubscriptions();
    }
  }, [state.context?.subscription, state.value]);

  useEffect(() => {
    setIsValidated(!!referralState?.context?.userAppliedReferralCode?.ref_code);
    setReferralCode(
      referralState?.context?.userAppliedReferralCode?.ref_code || ""
    );
  }, [referralState?.context?.userAppliedReferralCode]);

  const fetchSubscriptions = async () => {
    try {
      const fetchedSubscriptions = state.context?.subscription;
      setSubscription(fetchedSubscriptions);
      console.log(fetchedSubscriptions); // For debugging
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    }
  };

  const handleDeleteCode = () => {
    referralSend({ type: "DELETE_REFERRAL_CODE" });
    setShowDeleteConfirm(false);
  };

  const handleReferralValidation = () => {
    if (!referralCode.trim()) {
      toast.error("Please enter a referral code");
      return;
    }
    referralSend({
      type: "VALIDATE_CODE",
      code: referralCode,
    });
  };

  const handleSubscription = () => {
    send({ type: "VERIFY_TRANSACTION" });
  };

  const handleRemoveReferral = () => {
    referralSend({ type: "REMOVE_APPLIED_REFERRAL" });
    setIsValidated(false);
    setReferralCode("");
  };

  const RenderLoadingOverlay = () => <div className="loading-overlay" />;

  return (
    <div className={styles.apiContainer}>
      <div style={{ textAlign: "left" }}>
        <span className={styles.title}>Access</span>
      </div>

      <div className={styles.textDiv}>
        Welcome to your Access Dashboard! This central hub allows you to manage
        your platform access, track your active subscriptions, and monitor your
        usage. Here you can view your subscription status, remaining time, and
        access credentials. Soon, you'll also be able to manage your API keys
        and track your platform usage statistics all in one place.
      </div>

      <div className={styles.flexContainer}>
        <div className={styles.cardContainer}>
          <ConnectionOverlay>
            <div
              style={{ padding: 20, boxSizing: "border-box" }}
              className={styles.card}
            >
              <div className={styles.tooltipContainer}>
                <Tooltip id="permanent-access" page="access-page" />
              </div>

              <div className={styles.defaultInfo}>
                <h2 className={styles.defaultTitle}>Access</h2>
                <p>
                  Wallet Address:{" "}
                  {address
                    ? `${address.slice(0, 4)}....${address.slice(-4)}`
                    : "0x00....0000"}
                </p>
                <p>Balance: {state.context?.subscription?.balance || 0} YAI</p>
                {ENABLE_REFERRAL && (
                  <div className={styles.referralSection}>
                    <div className={styles.subtitle}>
                      Have a referral code? Enter it below for benefits:
                    </div>
                    <div className={styles.referralInputWrapper}>
                      <TextField
                        type="text"
                        value={referralCode}
                        onChange={(e) => {
                          // Only allow alphanumeric characters
                          const sanitizedValue = e.target.value.replace(
                            /[^a-zA-Z0-9]/g,
                            ""
                          );
                          setReferralCode(sanitizedValue);
                        }}
                        placeholder="Enter referral code (optional)"
                        variant="standard"
                        disabled={
                          referralState?.context?.isValidating || isValidated
                        }
                        className={isValidated ? styles.validated : ""}
                        error={
                          referralCode?.length > 0 && referralCode?.length < 3
                        }
                        helperText={
                          referralCode?.length > 0 && referralCode?.length < 3
                            ? "Referral code must be at least 3 characters"
                            : ""
                        }
                        sx={{
                          "& .MuiInputLabel-root": {
                            color: "white",
                          },
                          "& .MuiInput-root": {
                            color: "white",
                            "&:before": {
                              borderBottomColor: "white",
                            },
                            "&:hover:not(.Mui-disabled):before": {
                              borderBottomColor: "white",
                            },
                            "&.Mui-focused:after": {
                              borderBottomColor: "white",
                            },
                            "&.Mui-disabled": {
                              "&:before": {
                                borderBottomColor: "rgba(255, 255, 255, 0.6)",
                              },
                            },
                          },
                          "& .MuiInput-input": {
                            color: "white",
                            textAlign: "center",
                            "&.Mui-disabled": {
                              color: "rgba(255, 255, 255, 0.6)",
                              WebkitTextFillColor: "rgba(255, 255, 255, 0.6)",
                            },
                          },
                          "& .MuiFormHelperText-root": {
                            color: "#ff4444",
                            textAlign: "center",
                            marginTop: "8px",
                          },
                        }}
                      />
                      {isValidated && (
                        <>
                          {referralState.matches("removingAppliedReferral") ? (
                            "Removing..."
                          ) : (
                            <div
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={handleRemoveReferral}
                              disabled={referralState.matches(
                                "removingAppliedReferral"
                              )}
                            >
                              <img
                                src="/images/trash.svg"
                                alt="Remove"
                                className={styles.removeButton}
                              />
                            </div>
                          )}
                        </>
                      )}

                      {!isValidated && (
                        <div className={styles.referralButtonWrapper}>
                          <div
                            className={`${styles.buttonWallet} ${
                              !referralCode || referralCode?.length < 3
                                ? styles.buttonDisabled
                                : ""
                            }`}
                            disabled={
                              referralState?.context?.isValidating ||
                              !referralCode ||
                              referralCode?.length < 3
                            }
                            onClick={() => {
                              if (referralCode && referralCode?.length >= 3) {
                                handleReferralValidation();
                              }
                            }}
                          >
                            {referralState?.context?.isValidating
                              ? "Validating..."
                              : "Validate"}
                          </div>
                        </div>
                      )}
                    </div>
                    {isValidated && (
                      <div className={styles.validatedMessageContainer}>
                        <div className={styles.validatedMessage}>
                          ✓ Referral code applied successfully
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <p>
                  Status:{" "}
                  <span style={{ color: "#00FF00" }}>
                    {" "}
                    {state.matches("waitingForSubscription")
                      ? "Waiting for Subscription"
                      : state.matches("verifyingTransaction")
                      ? "Verifying Transaction"
                      : "Access Granted"}
                  </span>
                </p>
              </div>
            </div>
          </ConnectionOverlay>
        </div>
        <div className={styles.cardContainer}>
          <ConnectionOverlay>
            <div
              style={{ padding: 20, boxSizing: "border-box" }}
              className={styles.card}
            >
              <div className={styles.tooltipContainer}>
                <Tooltip id="subscription-access" page="access-page" />
              </div>

              <div className={styles.planDetails}>
                <h2 className={styles.planTitle}>Subscription Access</h2>
              </div>
              <div className={styles.subscriptionStatus}>
                <div className={styles.expiryInfo}>
                  <div>
                    <span>Expires on: </span>
                    <strong>
                      {subscription?.expiry_date
                        ? new Date(subscription.expiry_date).toLocaleDateString(
                            "en-GB"
                          )
                        : "No active subscription"}
                    </strong>
                  </div>
                  <div>
                    <span>Days remaining: </span>
                    <strong>
                      {subscription?.expiry_date
                        ? Math.max(
                            0,
                            Math.ceil(
                              (new Date(subscription.expiry_date) -
                                new Date()) /
                                (1000 * 60 * 60 * 24)
                            )
                          )
                        : 0}
                    </strong>
                  </div>
                </div>
              </div>

              {state.matches("waitingForSubscription") && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                    marginTop: "20px",
                  }}
                >
                  <div className={styles.subscriptionInfo}>
                    To extend subscription for 1 month or more, deposit{" "}
                    <strong>{SUBSCRIPTION_AMOUNT} $YAI or more</strong> to the
                    following address:
                    <div className={styles.addressContainer}>
                      <div className={styles.address}>
                        <strong>{SUBSCRIPTION_ADDRESS}</strong>
                      </div>
                      <button
                        className={styles.copyButton}
                        onClick={() => {
                          navigator.clipboard.writeText(SUBSCRIPTION_ADDRESS);
                          toast.success(
                            "Subscription address copied to clipboard!"
                          );
                        }}
                        title="Copy subscription address"
                      >
                        <img
                          src="/images/clipboard.svg"
                          alt="Copy"
                          className={styles.copyIcon}
                        />
                      </button>
                    </div>
                    <div className={styles.buttonContainer}>
                      <div
                        className={styles.buttonWallet}
                        onClick={handleSubscription}
                      >
                        <img src="/images/walletIcon.svg" alt="Wallet" />
                        Subscribe
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {state.matches("verifyingTransaction") && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                    marginTop: "20px",
                  }}
                >
                  <h2 className={styles.defaultTitle}>
                    Scanning for Subscription TX
                  </h2>
                  <div className={styles.subtitle}>
                    To subscribe for 1 month or more, deposit{" "}
                    <strong>{SUBSCRIPTION_AMOUNT} $YAI or more</strong> to the
                    following address:{" "}
                    <div className={styles.addressContainer}>
                      <div className={styles.address}>
                        {SUBSCRIPTION_ADDRESS}
                      </div>
                      <button
                        className={styles.copyButton}
                        onClick={() => {
                          navigator.clipboard.writeText(SUBSCRIPTION_ADDRESS);
                          toast.success(
                            "Subscription address copied to clipboard!"
                          );
                        }}
                        title="Copy subscription address"
                      >
                        <img
                          src="/images/clipboard.svg"
                          alt="Copy"
                          className={styles.copyIcon}
                        />
                      </button>
                    </div>
                  </div>

                  <div className={styles.loaderContainer}>
                    <Loader />
                  </div>
                </div>
              )}
              {!state.matches("waitingForSubscription") &&
                !state.matches("verifyingTransaction") && (
                  <div className={styles.subscriptionInfo}>
                    To extend subscription for 1 month or more, deposit{" "}
                    <strong>{SUBSCRIPTION_AMOUNT} $YAI or more</strong> to the
                    following address:{" "}
                    <div className={styles.addressContainer}>
                      <div className={styles.address}>
                        <strong>{SUBSCRIPTION_ADDRESS}</strong>
                      </div>
                      <button
                        className={styles.copyButton}
                        onClick={() => {
                          navigator.clipboard.writeText(SUBSCRIPTION_ADDRESS);
                          toast.success(
                            "Subscription address copied to clipboard!"
                          );
                        }}
                        title="Copy subscription address"
                      >
                        <img
                          src="/images/clipboard.svg"
                          alt="Copy"
                          className={styles.copyIcon}
                        />
                      </button>
                    </div>
                    <div className={styles.buttonContainer}>
                      <div
                        className={styles.buttonWallet}
                        onClick={handleSubscription}
                      >
                        <img src="/images/walletIcon.svg" alt="Wallet" />
                        Subscribe
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </ConnectionOverlay>
        </div>
        {ENABLE_REFERRAL && (
          <div className={styles.cardContainer}>
            <ConnectionOverlay>
              <div
                style={{ padding: 20, boxSizing: "border-box" }}
                className={styles.card}
              >
                <h2 className={styles.planTitle}>Referrals</h2>

                {state.context?.subscription?.balance >= MinTokenAmountLayout &&
                referralState?.context?.referralCode ? (
                  <>
                    <div className={styles.refCodeSection}>
                      <div className={styles.refCodeDisplay}>
                        <span className={styles.refLabel}>
                          Your Referral Code:
                        </span>
                        <div className={styles.refCodeWrapper}>
                          <span className={styles.refCode}>
                            {referralState?.context?.referralCode}
                          </span>
                          <div className={styles.codeActions}>
                            <button
                              className={styles.copyButton}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  referralState?.context?.referralCode
                                );
                                toast.success(
                                  "Referral code copied to clipboard!"
                                );
                              }}
                              title="Copy referral code"
                            >
                              <img
                                src="/images/clipboard.svg"
                                alt="Copy"
                                className={styles.copyIcon}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.refStats}>
                      <div className={styles.statItem}>
                        <span className={styles.statLabel}>
                          Total Referrals
                        </span>
                        <span className={styles.statValue}>
                          {referralState?.context?.referralStats?.total_usage ||
                            0}{" "}
                          users
                        </span>
                      </div>
                      <div className={styles.statItem}>
                        <span className={styles.statLabel}>
                          Total YAI Generated
                        </span>
                        <span className={styles.statValue}>
                          {sumAmounts(
                            referralState?.context?.referralStats?.usage
                          ) || 0}{" "}
                          YAI
                        </span>
                      </div>
                    </div>

                    <Dialog
                      open={showDeleteConfirm}
                      onClose={() => setShowDeleteConfirm(false)}
                      PaperProps={{
                        style: {
                          backgroundColor: "#1a1a1a",
                          color: "white",
                          border: "1px solid #333",
                        },
                      }}
                    >
                      <DialogTitle>Delete Referral Code</DialogTitle>
                      <DialogContent>
                        Are you sure you want to delete your referral code? This
                        action cannot be undone.
                      </DialogContent>
                      <DialogActions>
                        <button
                          className={`${styles.dialogButton} ${styles.cancelButton}`}
                          onClick={() => setShowDeleteConfirm(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className={`${styles.dialogButton} ${styles.deleteButton}`}
                          onClick={handleDeleteCode}
                          disabled={referralState.matches("deletingCode")}
                        >
                          {referralState.matches("deletingCode")
                            ? "Deleting..."
                            : "Delete"}
                        </button>
                      </DialogActions>
                    </Dialog>
                  </>
                ) : (
                  <div className={styles.noRefCode}>
                    {state.context?.subscription?.balance >=
                      MinTokenAmountLayout &&
                    !referralState?.context?.referralCode ? (
                      <>
                        <p>You haven't created a referral code yet.</p>
                        <button
                          className={styles.createRefButton}
                          onClick={() =>
                            referralSend({ type: "CREATE_REFERRAL_CODE" })
                          }
                        >
                          {referralState?.context?.isCreatingReferralCode
                            ? "Creating..."
                            : "Create Referral Code"}
                        </button>
                      </>
                    ) : (
                      !(
                        state.context?.subscription?.balance >
                        MinTokenAmountLayout
                      ) && (
                        <p>
                          You must hold at least 50,000 YAI to create a referral
                          code.
                        </p>
                      )
                    )}
                  </div>
                )}
              </div>
            </ConnectionOverlay>
          </div>
        )}
        <div className={styles.cardContainer}>
          <ConnectionOverlay>
            <div className={styles.card}>
              <div style={{ textDecoration: "none" }}>
                <div className={styles.buttonWallet}>
                  <img
                    src="/images/ApiDocsIcon.svg"
                    style={{ width: "20px", height: "20px" }}
                  />
                  Coming Soon
                </div>
              </div>
            </div>
          </ConnectionOverlay>
        </div>
      </div>
    </div>
  );
};

export default Api;
