import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header.js";
import Sidebar from "../../components/Sidebar/Sidebar.js";
import HamburgerIcon from "../Icons/HamburgerIcon.js";
import MaximizeIcon from "../Icons/MaximizeIcon.js";
import MinimizeIcon from "../Icons/MinimizeIcon.js";
import styles from "./Layout.module.css";

const DashboardLayout = () => {
  const [fullWidth, setFullWidth] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(
    window.innerWidth > 1100
  );

  const [sidebarToggled, setSidebarToggled] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1100 && !sidebarToggled) {
        setIsSidebarVisible(true);
      } else if (window.innerWidth <= 1100 && !sidebarToggled) {
        setIsSidebarVisible(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [sidebarToggled]);

  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
    setSidebarToggled(true);
  };

  const toggleWidth = () => {
    setFullWidth((prev) => !prev);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <>
      <div className={styles.hamburger} onClick={toggleSidebar}>
        <HamburgerIcon />
      </div>

      <div className={`${fullWidth ? styles.fullWidth : styles.mainContainer}`}>
        <div
          className={` ${fullWidth ? styles.fullWidth : styles.subContainer}`}
        >
          <div
            className={`${styles.leftContainer} ${
              isSidebarVisible ? styles.visible : ""
            }`}
          >
            {" "}
            <Sidebar onClick={handleLogoClick} />
          </div>
          <div className={`${fullWidth ? "" : styles.rightContainer}`}>
            <div className={styles.logo} onClick={handleLogoClick}>
              <img src="images/yaiLogo.svg" alt="Logo" />
            </div>
            <div className={`${fullWidth ? "" : styles.border}`}>
              <div className={styles.rightTopContainer}>
                <Header />
              </div>
              <div className={`${fullWidth ? "" : styles.gradient}`}>
                {/* <ConnectModal/> */}
                <div
                  className={`${
                    fullWidth
                      ? styles.fullWidthRightBottomContainer
                      : styles.rightBottomContainer
                  }`}
                >
                  <Outlet fullWidth={fullWidth} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <button onClick={toggleWidth}>
            {fullWidth ? <MinimizeIcon /> : <MaximizeIcon />}
          </button>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
