import React, { useState, useEffect } from "react";
import moment from "moment";
import styles from "./DaoComponents.module.css";
import { useDao } from "../../context/DaoContext";
import Loader from "../Loader/Loader";
import RefreshIcon from "../UI/RefreshIcon";

const ActiveProposals = ({
  proposals = [],
  onSelectProposal,
  selectedProposalId,
}) => {
  const { daoService, send: daoSend, isFetchingProposals } = useDao();
  const [now, setNow] = useState(moment());

  // Update the current time every minute to refresh countdown
  useEffect(() => {
    const timer = setInterval(() => {
      setNow(moment());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleRefresh = () => {
    if (!isFetchingProposals) {
      daoSend({ type: "FETCH_PROPOSALS" });
    }
  };

  if (!proposals || proposals.length === 0) {
    return (
      <div className={styles.proposalsContainer}>
        {isFetchingProposals && <Loader />}
        <h3 className={styles.containerTitle}>
          Active Proposals{" "}
          <div
            className={`${styles.refreshButton} ${
              isFetchingProposals ? styles.disabled : ""
            }`}
            onClick={handleRefresh}
          >
            <RefreshIcon className={styles.refreshIcon} />
          </div>
        </h3>
        <div className={styles.emptyState}>
          {isFetchingProposals ? (
            <Loader size="small" />
          ) : (
            "No active proposals available"
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.proposalsContainer}>
      <h3 className={styles.containerTitle}>
        {`Active Proposals (${proposals.length})`}
        <div
          className={`${styles.refreshButton} ${
            isFetchingProposals ? styles.disabled : ""
          }`}
          onClick={handleRefresh}
        >
          <RefreshIcon className={styles.refreshIcon} />
        </div>
      </h3>
      <div className={styles.proposalsList}>
        {proposals.map((proposal) => {
          let isClosed = false;
          // Calculate time remaining using moment
          let timeRemainingText = "";
          const endtime = moment.unix(proposal.end_date);
          if (now.isAfter(endtime)) {
            timeRemainingText = "Closed a while ago";
            isClosed = true;
            daoSend({ type: "REFRESH" });
          } else {
            const duration = moment.duration(endtime.diff(now));
            const days = Math.floor(duration.asDays());
            const hours = duration.hours();
            const minutes = duration.minutes();
            timeRemainingText = `${+days}d ${+hours}h ${+minutes}m remaining`;
          }

          return (
            <div
              key={proposal._id}
              className={`${styles.proposalItem} ${
                selectedProposalId === proposal._id ? styles.selected : ""
              }`}
              onClick={() => onSelectProposal(proposal._id)}
            >
              <div className={styles.proposalTitle}>{proposal.title}</div>
              <div
                className={`${
                  isClosed
                    ? styles.proposalTimeRemainingClosed
                    : styles.proposalTimeRemaining
                }`}
              >
                {timeRemainingText}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ActiveProposals;
