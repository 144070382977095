import React, { createContext, useContext, useState } from "react";
import { createAxiosInstance } from "../utils/axiosInstance";

const ErrorContext = createContext();

export const useErrorContext = () => useContext(ErrorContext);

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [isLimitExceeded, setIsLimitExceeded] = useState(false);

  console.log("isLimitExceeded", isLimitExceeded);
  const limitExceededModalOpen = () => {
    setIsLimitExceeded(true);
  };

  const limitExceededModalClose = () => {
    setIsLimitExceeded(false);
  };
  const axiosInstance = createAxiosInstance(setError, setIsLimitExceeded);

  const clearError = () => {
    setError(null);
  };

  return (
    <ErrorContext.Provider
      value={{
        error,
        setError,
        clearError,
        axiosInstance,
        isLimitExceeded,
        limitExceededModalOpen,
        limitExceededModalClose,
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};
