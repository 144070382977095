import React from "react";
import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers/react";
import styles from "./withConnectionOverlay.module.css"; // Create a CSS module for styling

// Component that accepts children directly
const ConnectionOverlay = ({ children }) => {
  const { isConnected } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();

  return (
    <div className={styles.wrapper}>
      {/* Children are always rendered */}
      <div
        className={!isConnected ? styles.blurredBackground : ""}
        style={{ height: "100%" }}
      >
        {children}
      </div>

      {/* Overlay only shown when not connected */}
      {!isConnected && (
        <div className={styles.overlay}>
          <div className={styles.modal}>
            <p>Please connect your wallet to continue</p>
            <div onClick={() => open()} className={styles.connectButton}>
              Connect Wallet
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConnectionOverlay;
