import React, { useState, useRef, useEffect } from "react";
import ChatMessages from "./ChatMessages";
import ChatInput from "./ChatInput";
import DefaultQuestions from "./DefaultQuestions";
import { API_CONFIG, DEFAULT_QUESTIONS } from "../../constants/constants";
import { useChatBoxContext } from "../../context/chatbotContext";

function ChatInterface({ isLoading, disabled }) {
  const [isTypingComplete, setIsTypingComplete] = useState(true);
  const [showDefaultQuestions, setShowDefaultQuestions] = useState(true);
  const messagesContainerRef = useRef(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const { state, send: chatbotSend } = useChatBoxContext();

  const isProcessing =
    state.matches("processingMessage") || state.matches("processingQuickAsk");

  useEffect(() => {
    if (!initialLoadComplete && messagesContainerRef.current) {
      const inputSection = document.querySelector(".chat-input-container");
      if (inputSection) {
        messagesContainerRef.current.scrollTop =
          messagesContainerRef.current.scrollHeight - inputSection.offsetHeight;
      } else {
        messagesContainerRef.current.scrollTop =
          messagesContainerRef.current.scrollHeight;
      }
      setInitialLoadComplete(true);
    }
  }, []);

  // const fetchVisualizationData = async (responseId) => {
  //   console.log(
  //     `[fetchVisualizationData] Starting request for responseId: ${responseId}`
  //   );
  //   try {
  //     const response = await fetch(
  //       `${API_CONFIG.BASE_URL}/proxy_llm_data?query_id=${responseId}`,
  //       {
  //         headers: {
  //           "X-Wallet-Address": API_CONFIG.DEFAULT_WALLET,
  //           "Y-API-KEY": "yai-internal-master-key-blablabla",
  //         },
  //       }
  //     );

  //     if (!response.ok) {
  //       console.log(
  //         `[fetchVisualizationData] Request failed with status: ${response.status}`
  //       );
  //       throw new Error("Failed to fetch visualization data");
  //     }

  //     const data = await response.json();
  //     console.log(
  //       `[fetchVisualizationData] Request successful for responseId: ${responseId}`
  //     );
  //     return data;
  //   } catch (error) {
  //     console.error("[fetchVisualizationData] Error:", error);
  //     return null;
  //   }
  // };

  // const handleMessageClick = async (responseId) => {
  //   if (responseId && responseId !== selectedMessageId) {
  //     setSelectedMessageId(responseId);
  //     const visualData = await fetchVisualizationData(responseId);
  //     onApiResponse(visualData);
  //   }
  // };

  // const sendMessage = async (message) => {
  //   console.log(`[sendMessage] Starting request with message: "${message}"`);
  //   setIsLoading(true);
  //   try {
  //     // Add user message to chat
  //     setMessages((prev) => [
  //       ...prev,
  //       {
  //         role: "user",
  //         content: message,
  //         id: null,
  //       },
  //     ]);

  //     console.log("[sendMessage] Making llm_query API call...");
  //     // Call llm_query API
  //     const response = await fetch(
  //       `${API_CONFIG.BASE_URL}/proxy_llm_query?prompt=${encodeURIComponent(
  //         message
  //       )}`,
  //       {
  //         headers: {
  //           "X-Wallet-Address": API_CONFIG.DEFAULT_WALLET,
  //           // "Y-API-KEY": "yai-internal-master-key-blablabla",
  //         },
  //       }
  //     );

  //     if (!response.ok) {
  //       console.log(
  //         `[sendMessage] Request failed with status: ${response.status}`
  //       );
  //       throw new Error("API request failed");
  //     }

  //     const data = await response.json();
  //     console.log("[sendMessage] API call successful, response:", data);

  //     // Add AI response to chat with its ID
  //     setMessages((prev) => [
  //       ...prev,
  //       {
  //         role: "assistant",
  //         content: data.response_txt,
  //         id: data.response_id,
  //       },
  //     ]);

  //     // Set as selected and fetch visualization
  //     setSelectedMessageId(data.response_id);
  //     console.log("[sendMessage] Fetching visualization data...");
  //     const visualData = await fetchVisualizationData(data.response_id);
  //     onApiResponse(visualData);
  //   } catch (error) {
  //     console.error("[sendMessage] Error:", error);
  //     setMessages((prev) => [
  //       ...prev,
  //       {
  //         role: "system",
  //         content: "Sorry, there was an error processing your request.",
  //         id: null,
  //       },
  //     ]);
  //   } finally {
  //     setIsLoading(false);
  //     console.log("[sendMessage] Request completed");
  //   }
  // };

  const handleDefaultQuestion = (question) => {
    chatbotSend({ type: "SELECT_QUICK_ASK", text: question });
  };

  const handleTypingComplete = (complete) => {
    setIsTypingComplete(complete);
    setShowDefaultQuestions(true);

    if (complete) {
      setTimeout(() => {
        if (messagesContainerRef.current) {
          messagesContainerRef.current.scrollTop =
            messagesContainerRef.current.scrollHeight;
        }
      }, 100);
    }
  };

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (!container) return;

    const scrollToBottom = () => {
      container.scrollTop = container.scrollHeight;
    };

    // Initial scroll
    scrollToBottom();

    const observer = new MutationObserver(() => {
      scrollToBottom();
    });

    observer.observe(container, {
      childList: true,
      subtree: true,
      characterData: true,
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="chat-interface">
      <div
        ref={messagesContainerRef}
        className="chat-messages-container"
        style={{
          // border: '1px solid rgba(26, 255, 0, 0.2)',
          borderRadius: "8px",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
          // flex: '1 1',
          height: "100%",
          maxHeight: "60vh",
          overflowY: "auto",
        }}
      >
        <ChatMessages
          messages={state?.context?.messages}
          disabled={disabled}
          isLoading={
            state.matches("processingQuickAsk") ||
            state.matches("processingMessage")
          }
          selectedMessageId={state?.context?.selectedMessageId}
          onMessageClick={(responseId) => {
            chatbotSend({
              type: "VIEW_MESSAGE_DETAILS",
              output: {
                response_id: responseId,
              },
            });
          }}
          onSendMessage={(message) => {
            chatbotSend({ type: "SEND_MESSAGE", text: message });
          }}
          onTypingComplete={handleTypingComplete}
        />
        <div
          className="default-questions-container"
          style={{
            padding: "10px",
            border: "1px solid rgba(26, 255, 0, 0.2)",
            borderRadius: "8px",
          }}
        >
          <DefaultQuestions
            DEFAULT_QUESTIONS={state.context.quickAsks}
            onQuestionClick={handleDefaultQuestion}
            isLoading={isProcessing}
            isTypingComplete={isTypingComplete}
            disabled={disabled}
          />
        </div>
      </div>
      <ChatInput
        onSendMessage={(message) => {
          chatbotSend({ type: "SEND_MESSAGE", text: message });
        }}
        isLoading={isLoading}
        typingComplete={isTypingComplete}
        disabled={disabled}
      />
    </div>
  );
}

export default ChatInterface;
