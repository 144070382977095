import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import React, { useContext, useEffect, memo, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { AuthContext } from "../AuthWrapper/AuthWrapper.js";
import withAuthenticationGuard from "../components/guards/withAuthWrapper";
import MarketOverview from "../components/MarketOverviewModel/MarketOverview.js";
import HistoricalOverviewModel from "../components/ModelHistoricalOverview/HistoricalOverview.js";
import ModelSentiment from "../components/ModelSentiment/ModelSentiment.js";
import ZscatterModel from "../components/ZscatterModel/ZscatterModel.js";
import { AMOUNT_NEEDED_FOR_TEMPORARY_SUBSCRIPTION } from "../constants/constants";
import { useChatBoxContext } from "../context/chatbotContext";
import Loader from "../components/Loader/Loader";

const Model = ({ fullWidth }) => {
  const [searchParams] = useSearchParams();
  const model = searchParams.get("model");
  const projectID = searchParams.get("projectID");
  const { isConnected } = useWeb3ModalAccount();
  const { state, send } = useContext(AuthContext);
  const { state: chatbotState } = useChatBoxContext();

  const isLoading =
    state.matches("idle") ||
    state.matches("signingIn") ||
    state.matches("waitingForSubscription") ||
    state.matches("verifyingTransaction") ||
    state.matches("loggingOut") ||
    state.matches("loadingDashboardState") ||
    chatbotState.matches("fetchingSubscriptions");

  useEffect(() => {
    if (
      state.matches("model") ||
      state.matches("home") ||
      state.matches("strategy") ||
      state.matches("apiKey") ||
      state.matches("chat") ||
      state.matches("dao")
    )
      send({ type: "CHECK_ROUTE" });
  }, [send]);

  const RenderLoadingOverlay = () => <div className="loading-overlay" />;
  const renderComponents = (data) => (
    <>
      <HistoricalOverviewModel
        key={fullWidth}
        dashboardState={data}
        initialModel={data ? model : null}
        initialProjectId={data ? projectID : null}
      />
      <MarketOverview
        dashboardState={data}
        initialProjectId={data ? projectID : null}
      />
      <ModelSentiment
        dashboardState={data}
        initialProjectId={data ? projectID : null}
      />
      {/* <ModelOverview dashboardState={data} initialModel={data ? model : null} /> */}

      <ZscatterModel
        dashboardState={data}
        initialProjectId={data ? projectID : null}
      />
      {/* <Strategies dashboardState={data} initialProjectId={data ? projectID : null} /> */}
    </>
  );
  const RenderLoader = memo(() => <Loader />);

  // Extract the access check into a variable
  const hasValidAccess = useMemo(() => {
    // First, verify all required objects exist
    if (!isConnected) return false;
    if (!chatbotState?.context?.subscription) return false;

    const subscription = chatbotState.context.subscription;
    const expiryDate = subscription.expiry_date;
    const balance = subscription.balance || 0;

    // Now check subscription validity
    const hasValidExpiry =
      expiryDate && new Date(expiryDate).getTime() > new Date().getTime();
    const hasValidBalance = balance >= AMOUNT_NEEDED_FOR_TEMPORARY_SUBSCRIPTION;

    return hasValidExpiry || hasValidBalance;
  }, [isConnected, chatbotState?.context?.subscription]);

  return (
    <>
      {hasValidAccess && renderComponents(state.context.dashboardState)}
      {isLoading && <RenderLoadingOverlay />}
      {isLoading && <RenderLoader />}
    </>
  );
};

export default withAuthenticationGuard(Model);
