import { memo, React, useContext, useEffect } from "react";
import { AuthContext } from "../AuthWrapper/AuthWrapper.js";
import GeneralOverview from "../components/GeneralOverview/GeneralOverview";
import StateHeatmap from "../components/Heatmap/StateHeatmap";
import Loader from "../components/Loader/Loader";
import MarketOverview from "../components/MarketOverview/MarketOverview";
import Technicals from "../components/Technicals/Technicals";

const Home = (props) => {
  const { state, send } = useContext(AuthContext);

  console.log("state >>>>>>>>>>>>>>>>>>", state.value);
  const isLoading =
    state.matches("loadingDashboardState") ||
    state.matches("loadingHomeRouteData");

  useEffect(() => {
    if (
      state.matches("model") ||
      state.matches("strategy") ||
      state.matches("apiKey") ||
      state.matches("idle") ||
      state.matches("chat") ||
      state.matches("dao")
    )
      send({ type: "CHECK_ROUTE" });
  }, [send]);

  const RenderLoadingOverlay = () => (
    <div
      style={{
        position: "absolute",
        height: "100%",
        width: "100%",
        backdropFilter: "blur(1px)",
        pointerEvents: "none",
        zIndex: 10,
        top: "0",
        left: "0",
      }}
    />
  );

  const RenderLoader = memo(() => <Loader />);

  const renderComponents = (data) => (
    <>
      <GeneralOverview dashboardState={data} />
      <Technicals dashboardState={data} />
      <MarketOverview dashboardState={data} />
      <StateHeatmap dashboardState={data} />
    </>
  );

  return (
    <>
      <>
        {renderComponents(state.context.dashboardState)}
        {isLoading && <RenderLoadingOverlay />}
        {isLoading && <RenderLoader />}
      </>
    </>
  );
};

export default Home;
