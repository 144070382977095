import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../AuthWrapper/AuthWrapper";
import styles from "./InsufficientBalanceModal.module.css";
import { toast } from "react-toastify";
import {
  SUBSCRIPTION_ADDRESS,
  SUBSCRIPTION_AMOUNT,
  PERMANENT_AMOUNT,
  EXTERNAL_LINKS as SOCIAL_MEDIA_LINKS,
} from "../../constants/constants";
import { useReferral } from "../../AuthWrapper/RefferalWrapper";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
const InsufficientBalanceModal = () => {
  const { state, send } = useContext(AuthContext);
  const { state: referralState, send: referralSend } = useReferral();

  const [referralCode, setReferralCode] = useState("");
  const navigate = useNavigate();
  const [isValidated, setIsValidated] = useState(
    !!referralState?.context?.userAppliedReferralCode?.ref_code
  );

  useEffect(() => {
    setIsValidated(!!referralState?.context?.userAppliedReferralCode?.ref_code);
    setReferralCode(referralState?.context?.userAppliedReferralCode?.ref_code);
  }, [referralState?.context?.userAppliedReferralCode]);

  const handleSubscription = () => {
    send({ type: "VERIFY_TRANSACTION" });
  };

  const handleReferralValidation = async () => {
    if (!referralCode.trim()) {
      toast.error("Please enter a referral code");
      return;
    }
    referralSend({
      type: "VALIDATE_CODE",
      code: referralCode,
    });
  };

  const handleRemoveReferral = () => {
    referralSend({ type: "REMOVE_APPLIED_REFERRAL" });
    setIsValidated(false);
    setReferralCode("");
  };

  return (
    <div className={styles.modalOverlay}>
      {/* {state.matches('waitingForSubscription') && ( */}
      <div className={styles.modalContent}>
        <div className={styles.title}>
          Insufficient Balance / No Subscription detected
        </div>

        {/* <div className={styles.subtitle}>
          Hold atleast <strong>{PERMANENT_AMOUNT} $YAI </strong> for permanent
          access.
        </div>
        <div className={styles.subtitle}>
          Subscribe for 1 month or more by depositing{" "}
          <strong>{SUBSCRIPTION_AMOUNT} $YAI or more</strong> to the following
          address: <div className={styles.address}>{SUBSCRIPTION_ADDRESS}</div>
        </div> */}
         <div
            className={styles.buttonLogOut}
            style={{ marginTop: "30px" }}
            onClick={() => {
              send({ type: "SIGN_OUT" });
              // localStorage.removeItem("authState");
              // disconnect();
            }}
          >
            <img src="/images/logoutIcon.svg" alt="Log Out" />
            Log Out
          </div>
        <a
          href="https://www.coingecko.com/en/coins/y"
          style={{ marginTop: "10px" }}
          className={`${styles.buttonWallet} ${styles.tooltipContainer}`}
          // onClick={() => open()}
        >
          {/* <img src="/images/walletIcon.svg" alt="Wallet" /> */}
          Buy YAI
          <span className={styles.tooltip}>
            Third party platform link. YAI can not be held responsible for token
            availability, liquidity or stability of third party platforms
          </span>
        </a>

        <div
          style={{ marginTop: "10px" }}
          className={styles.buttonWallet}
          onClick={() => navigate("/access")}
        >
          Access
        </div>
        <div className={styles.socialMedia}>
          <a
            href={SOCIAL_MEDIA_LINKS.X}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{
                height: "30px",
                width: "30px",
                background: "#151814",
                padding: "10px",
                borderRadius: "50%",
              }}
              src="/images/twitter.svg"
              alt="X"
            />
          </a>
          <a
            href={SOCIAL_MEDIA_LINKS.Telegram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{
                height: "30px",
                width: "30px",
                background: "#151814",
                padding: "10px",
                borderRadius: "50%",
              }}
              src="/images/telegram.svg"
              alt="Telegram"
            />
          </a>
          <a
            href={SOCIAL_MEDIA_LINKS.Docs}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{ height: "50px", width: "50px" }}
              src="/images/docsIcon.svg"
              alt="Docs"
            />
          </a>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default InsufficientBalanceModal;
