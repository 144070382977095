import React, { useContext, useEffect, useMemo } from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import withAuthenticationGuard from "../components/guards/withAuthWrapper";
import { AuthContext } from "../AuthWrapper/AuthWrapper";
import { useDao } from "../context/DaoContext";
import ActiveProposals from "../components/DaoComponents/ActiveProposals";
import ClosedProposals from "../components/DaoComponents/ClosedProposals";
import SelectedProposal from "../components/DaoComponents/SelectedProposal";
import VoteComponent from "../components/DaoComponents/VoteComponent";
import { MinTokenAmountLayout } from "../constants/constants";
import styles from "../components/DaoComponents/DaoComponents.module.css";
import { useChatBoxContext } from "../context/chatbotContext";

const Dao = ({ fullWidth }) => {
  const { isConnected } = useWeb3ModalAccount();
  const { state, send } = useContext(AuthContext);
  const { daoService, send: daoSend } = useDao();
  const { state: chatbotState } = useChatBoxContext();

  const { activeProposals, closedProposals, selectedProposal, userVote } =
    daoService.context;

  useEffect(() => {
    if (
      state.matches("model") ||
      state.matches("home") ||
      state.matches("strategy") ||
      state.matches("apiKey") ||
      state.matches("chat")
    ) {
      send({ type: "CHECK_ROUTE" });
    }
  }, [send]);

  const hasValidAccess = useMemo(() => {
    // First, verify all required objects exist
    if (!isConnected) return false;
    if (!chatbotState?.context?.subscription) return false;

    const subscription = chatbotState.context.subscription;
    const balance = subscription.balance || 0;
    const hasValidBalance = balance >= MinTokenAmountLayout;

    return hasValidBalance;
  }, [isConnected, chatbotState?.context?.subscription]);

  useEffect(() => {
    if (hasValidAccess) {
      daoSend({ type: "FETCH_PROPOSALS" });
    }
  }, [hasValidAccess, daoSend]);

  // Handle selecting a proposal
  const handleSelectProposal = (proposalId) => {
    daoSend({
      type: "SELECT_PROPOSAL",
      proposalId,
    });
  };

  const handleVote = (proposalId, optionId) => {
    daoSend({
      type: "VOTE",
      proposalId,
      optionId,
    });
  };

  const renderDaoContent = () => (
    <div className={styles.daoPageGrid}>
      <div className={styles.selectedProposalGrid}>
        <SelectedProposal proposal={selectedProposal} />
      </div>

      <div className={styles.voteComponentGrid}>
        <VoteComponent
          proposal={selectedProposal}
          userVote={userVote}
          onVote={handleVote}
        />
      </div>

      <div className={styles.activeProposalsGrid}>
        <ActiveProposals
          proposals={activeProposals}
          selectedProposalId={selectedProposal?._id}
          onSelectProposal={handleSelectProposal}
        />
      </div>

      <div className={styles.closedProposalsGrid}>
        <ClosedProposals
          proposals={closedProposals}
          selectedProposalId={selectedProposal?._id}
          onSelectProposal={handleSelectProposal}
        />
      </div>
    </div>
  );

  return <>{hasValidAccess ? renderDaoContent() : ""}</>;
};

export default withAuthenticationGuard(Dao);
