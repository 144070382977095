import React, { useEffect, useMemo, useState } from "react";
import styles from "./DaoComponents.module.css";
import moment from "moment";
import { useDao } from "../../context/DaoContext";
import Loader from "../Loader/Loader";
const VoteComponent = ({ proposal, userVote, onVote }) => {
  const [selectedOption, setSelectedOption] = useState(userVote || null);
  const [changingVote, setChangingVote] = useState(false);

  const { isVoting, isSelectingProposal, isFetchingProposals } = useDao();

  useEffect(() => {
    if (userVote !== selectedOption) setSelectedOption(userVote);
  }, [userVote]);

  const proposalStatus = useMemo(() => {
    if (!proposal) return null;
    const now = moment();
    const endtime = moment.unix(proposal.end_date);
    if (now.isAfter(endtime)) {
      return "closed";
    }
    return "open";
  }, [proposal]);

  if (isSelectingProposal || isFetchingProposals) {
    return (
      <div className={styles.voteContainer}>
        <Loader />
      </div>
    );
  }

  if (!proposal) {
    return <div className={styles.voteContainer}></div>;
  }

  const handleOptionSelect = (optionId) => {
    if (proposalStatus === "closed") return;
    if (optionId && (changingVote || !userVote)) setSelectedOption(optionId);
  };

  const handleVote = () => {
    if (selectedOption && proposalStatus === "open") {
      onVote(proposal._id, selectedOption);
      setChangingVote(false);
    }
  };

  const handleChangeVote = () => {
    if (proposalStatus === "closed") return;
    setChangingVote(true);
  };

  const handleCancelChange = () => {
    setChangingVote(false);
    setSelectedOption(userVote);
  };

  return (
    <div className={styles.voteContainer}>
      <h3 className={styles.containerTitle}>Vote</h3>

      {userVote && !changingVote && (
        <div className={styles.alreadyVoted}>Vote Submitted</div>
      )}

      {proposalStatus === "closed" && (
        <div className={styles.votingClosed}>
          Voting is closed for this proposal
        </div>
      )}

      <div className={styles.optionsRadioGroup}>
        {proposal.options.map((option) => (
          <div
            key={option.id}
            className={`${styles.optionRadio} ${
              selectedOption === option.id ? styles.selected : ""
            } ${
              (userVote && !changingVote) || proposalStatus === "closed"
                ? styles.disabled
                : ""
            }`}
            onClick={() => handleOptionSelect(option.id)}
          >
            <div className={styles.radioButton}>
              {selectedOption === option.id && (
                <div className={styles.radioInner} />
              )}
            </div>
            <div className={styles.radioLabel}>{option.text}</div>
          </div>
        ))}
      </div>

      {userVote && !changingVote && proposalStatus === "open" ? (
        <div className={styles.voteButton} onClick={handleChangeVote}>
          Change Vote
        </div>
      ) : proposalStatus === "open" ? (
        <div className={styles.buttonGroup}>
          <div
            className={`${styles.voteButton} ${
              !selectedOption ? styles.disabled : ""
            }`}
            onClick={handleVote}
          >
            Submit Vote
          </div>
          {userVote && changingVote && (
            <div className={styles.cancelButton} onClick={handleCancelChange}>
              Cancel
            </div>
          )}
        </div>
      ) : null}
      {isVoting && <Loader />}
    </div>
  );
};

export default VoteComponent;
