import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  forwardRef,
} from "react";
import { VALID_SYMBOLS } from "../../constants/constants";
import Avatar from "../Avatar/Avatar";
import MessageProcessing from "../Loader/MessageLoader";
import DefaultQuestions from "./DefaultQuestions";

const ChatMessages = forwardRef(
  (
    {
      messages,
      isLoading,
      selectedMessageId,
      onMessageClick,
      onSendMessage,
      onTypingComplete,
      disabled,
    },
    ref
  ) => {
    const localRef = useRef(null);
    const messagesRef = ref || localRef;
    const [typingMessage, setTypingMessage] = useState(null);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);

    const scrollToBottom = useCallback(() => {
      if (messagesRef.current) {
        const element = messagesRef.current;
        element.scrollTop = element.scrollHeight;
      }
    }, [messagesRef]);

    useEffect(() => {
      if (!initialLoadComplete && messages.length > 0) {
        scrollToBottom();
        setInitialLoadComplete(true);
      }
    }, [messages, initialLoadComplete, scrollToBottom]);

    useEffect(() => {
      scrollToBottom();
    }, [messages.length, typingMessage, scrollToBottom]);

    useEffect(() => {
      if (!typingMessage && messages.length > 0) {
        scrollToBottom();
      }
    }, [typingMessage, messages.length, scrollToBottom]);

    const formatMessage = (content, isAssistant) => {
      if (!content) return "";
      if (!isAssistant) return content;

      const symbolPattern = new RegExp(
        `\\b(${VALID_SYMBOLS.join("|")})\\b`,
        "g"
      );
      const pricePattern = /\$\d+(?:,\d{3})*(?:\.\d+)?/g;
      const percentagePattern = /[-+]?\d*\.?\d+\s*%/g;
      const zScorePattern = /[-+]?\d+\.?\d*/g;

      let parts = content.split(
        /((?:\$\d+(?:,\d{3})*(?:\.\d+)?)|(?:[-+]?\d*\.?\d+\s*%)|(?:-?\d+\.?\d*)|(?:BTC|ETH|SOL|BNB|WIF|DOGE|DOT|ADA|LINK|XRP))/gi
      );

      return parts.map((part, index) => {
        if (VALID_SYMBOLS.includes(part)) {
          return (
            <a
              key={`sym-${index}`}
              href={`https://dashboard.yoracle.ai/model?model=oracle&projectID=${part}`}
              target="_blank"
              rel="noopener noreferrer"
              className="symbol-highlight"
            >
              {part}
            </a>
          );
        }

        if (pricePattern.test(part)) {
          return (
            <span key={`price-${index}`} className="price-highlight">
              {part}
            </span>
          );
        }

        if (percentagePattern.test(part)) {
          const value = parseFloat(part);
          const percentClass =
            value >= 0 ? "percent-positive" : "percent-negative";
          return (
            <span
              key={`pct-${index}`}
              className={`percent-highlight ${percentClass}`}
            >
              {part}
            </span>
          );
        }

        if (zScorePattern.test(part) && !part.includes("%")) {
          const score = parseFloat(part);
          let zScoreClass = "zscore-neutral";
          if (score > 1.5) zScoreClass = "zscore-high";
          else if (score < -1.5) zScoreClass = "zscore-low";
          return (
            <span
              key={`zscore-${index}`}
              className={`zscore-highlight ${zScoreClass}`}
            >
              {part}
            </span>
          );
        }

        return part;
      });
    };

    useEffect(() => {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage?.role === "assistant") {
        if (onTypingComplete) onTypingComplete(false);

        setTypingMessage({
          ...lastMessage,
          displayContent: "",
          fullContent: lastMessage.content,
        });

        let i = 0;
        const typeInterval = setInterval(() => {
          if (i < lastMessage.content.length) {
            setTypingMessage((prev) => ({
              ...prev,
              displayContent: lastMessage.content.slice(0, i + 1),
            }));
            i++;
          } else {
            clearInterval(typeInterval);
            setTypingMessage(null);

            if (onTypingComplete) {
              onTypingComplete(true);
            }
          }
        }, 5);

        return () => clearInterval(typeInterval);
      }
    }, [messages]);

    return (
      <div className="chat-messages">
        {messages.slice(0, -1).map((message, index) => (
          <div className="message-group" key={index}>
            {message.role === "system" && (
              <Avatar image={"/images/headerLogo.svg"} />
            )}
            <div
              className={`message ${message.role} ${
                message.id && message.id === selectedMessageId ? "selected" : ""
              } ${message.id ? "clickable" : ""}`}
              onClick={() =>
                message.id && disabled ? null : onMessageClick(message.id)
              }
            >
              {formatMessage(message.content, message.role === "assistant")}
            </div>
          </div>
        ))}

        {messages.length > 0 &&
          messages[messages.length - 1].role === "user" && (
            <div ref={messagesRef} />
          )}

        {typingMessage ? (
          <div className="message-group">
            <Avatar image={"/images/headerLogo.svg"} />
            <div
              className={`message ${typingMessage.role} ${
                typingMessage.id && typingMessage.id === selectedMessageId
                  ? "selected"
                  : ""
              } ${typingMessage.id ? "clickable" : ""}`}
              onClick={() =>
                typingMessage.id && disabled
                  ? null
                  : onMessageClick(typingMessage.id)
              }
            >
              {formatMessage(typingMessage.displayContent, true)}
              <span className="typing-cursor">|</span>
            </div>
          </div>
        ) : (
          messages.length > 0 && (
            <div className="message-group">
              {(messages[messages.length - 1].role === "system" ||
                messages[messages.length - 1].role === "assistant") && (
                <Avatar image={"/images/headerLogo.svg"} />
              )}
              <div
                className={`message ${messages[messages.length - 1].role} ${
                  messages[messages.length - 1].id &&
                  messages[messages.length - 1].id === selectedMessageId
                    ? "selected"
                    : ""
                } ${messages[messages.length - 1].id ? "clickable" : ""}`}
                onClick={() =>
                  messages[messages.length - 1].id && disabled
                    ? null
                    : onMessageClick(messages[messages.length - 1].id)
                }
              >
                {formatMessage(
                  messages[messages.length - 1].content,
                  messages[messages.length - 1].role === "assistant"
                )}
              </div>
            </div>
          )
        )}

        {isLoading && !typingMessage && (
          <div className="message-group">
            <Avatar image={"/images/headerLogo.svg"} />
            <MessageProcessing />
          </div>
        )}

        <div ref={messagesRef} />
      </div>
    );
  }
);

export default ChatMessages;
