import { assign, fromPromise, setup } from "xstate";
import { createAxiosInstance } from "../utils/axiosInstance";

export const daoMachine = setup({
  types: {},
}).createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QQIYHsB0AbNKIEsA7KAYgjULAyIDc0BrKnPABQCc0AHNWFLWAIIBjAC5o2AbQAMAXUShusfCPwV5IAB6IAHACZdGACwBGE1IDs2gMwA2AKwBOY+YA0IAJ6Ird4xjuPtB38HXStDcwcAX0i3VExmAmISMDYONgxOLBQRADNxAFtsXAh2Lh4+QVFxaTkkEEVlVUJ1LQQ9AxMzS1tHZzdPBF0pA11DB3GHGycpK27o2PRqCCwwEgBlAFEAGQ2AYQAVAH0WACUAeRYztYEtmvUGlTU61uNdawxtV7GZwNNJ-q8hkMRnM9hsbzsuh8M3M8xAcSWKxIJw2ADEUWsABJ3OoPJotRAhXxAuyzdq6UH6AEIKxSXxSRwOOnmOxjKy6GyGOEIhJEKClRR8MgUKi0BhMYoC8pYYRiSSye48RpPUAvN5WD5fJlWX5jGzUtkYSycuzaFk6oZ2bmLXnEKW8LDJVLiDJZXIFIqsDiCmVVeW1BRKx7NZ6IYzaVkYOn+SGTcxWHX6jyIcHmD42Wm6Jk2CPGKzGa2YTje6VbYqQEgANTO+w2OMDSmDBMGOowFr0-hsP2MGepZpsUYjDkMUk+4ZZVpi8MWxbKDrLeArmx2B2O50u11uCtxQfxoZb2jbeg7di7Op7VmpGYHdMzxicsashYyJfn5YgyLRGOx24bypDqqIKEh7tlCp7dr2yY0rMfjaJy5j6DY8ZMgWU4InQKhJOQlDUIQdCMBgGFgLK1S-vUu4qpoYbqpqozarq-xQeYERGlYQTaGakLBFEaGLBhfJOmkrrZHkbCFERJH+oqjZ7oBCCvO8nx0T8Th6n28YYPeFLGHYFj+Ayz4pGkn7ohsWL1uRMmUS8w7AkScEcq8DgIXYfa+LpUieRSCEJnSNjRFOhBoBAcDqHE0n-s2AC0SYDDFUaeYlSVJbCvHxMUfIRU2+5AgObzTK8ETsjp1K0u5cGGHBdjxoh-lpYiYBZbJVHyVI4wYDmLJfKYCauVBthpkE+aGHRoLhHVCzpXgfL2nwTXWWGPgDpVhgJmxzEJqMV55m2bFdpMzjGKYk6TS+c58AuIUQPNAEtSNfaclG3iBI48YWEhz78cQN3NsYDK+Jxwy6eteaGH2I6Dr04JHSOdKGc6bA-fuQwRm2Pi6cOWYhLMV7hEYgRdiy4yrQFkRAA */
  id: "dao",
  initial: "idle",
  context: {
    activeProposals: [],
    closedProposals: [],
    selectedProposal: null,
    userVote: null,
    error: null,
    isLoading: false,
  },
  states: {
    loading: {
      entry: assign({
        isLoading: true,
        selectedProposal: null,
        userVote: null,
        activeProposals: [],
        closedProposals: [],
        error: null,
      }),
      invoke: {
        id: "loadProposalsActor",
        src: "loadProposals",

        onDone: {
          target: "loadingProposal",
          actions: assign({
            activeProposals: ({ event }) => event.output.active || [],
            closedProposals: ({ event }) => event.output.closed || [],
            selectedProposal: ({ event }) =>
              event.output.active[0] || event.output.closed[0] || null,
            error: null,
            isLoading: false,
          }),
        },
        onError: {
          target: "error",
          actions: assign({
            error: ({ event }) => event.error.message,
            isLoading: false,
          }),
        },
      },
    },
    idle: {
      on: {
        SELECT_PROPOSAL: {
          target: "loadingProposal",
          actions: assign({
            selectedProposal: null,
            userVote: null,
          }),
        },
        FETCH_PROPOSALS: "loading",
      },
    },
    loadingProposal: {
      entry: assign({
        isLoading: true,
      }),
      invoke: {
        id: "loadProposalActor",
        src: "loadProposal",
        input: ({ context, event }) => ({
          proposalId:
            event.proposalId ||
            event.output.proposal_id ||
            context.selectedProposal._id,
        }),
        onDone: {
          target: "proposalLoaded",
          actions: assign({
            selectedProposal: ({ event }) => event.output?.proposal,
            userVote: ({ event }) => event.output?.proposal.user_vote || null,
            error: null,
            isLoading: false,
          }),
        },
        onError: {
          target: "error",
          actions: assign({
            error: ({ event }) => event.error.message,
            isLoading: false,
          }),
        },
      },
    },
    proposalLoaded: {
      on: {
        VOTE: {
          target: "voting",
        },
        SELECT_PROPOSAL: {
          target: "loadingProposal",
          actions: assign({
            selectedProposal: null,
            userVote: null,
          }),
        },
        REFRESH: "loading",
      },
    },
    voting: {
      entry: assign({
        isLoading: true,
      }),
      invoke: {
        id: "voteActor",
        src: "vote",
        input: ({ context, event }) => ({
          proposalId: event.proposalId,
          optionId: event.optionId,
        }),
        onDone: {
          target: "loadingProposal",
          actions: assign({
            error: null,
            isLoading: false,
          }),
        },
        onError: {
          target: "error",
          actions: assign({
            error: ({ event }) => event.error.message,
            isLoading: false,
          }),
        },
      },
    },
    error: {
      on: {
        REFRESH: "loading",
      },
    },
  },
});

export const createDaoService = (setError) => {
  const axiosInstance = createAxiosInstance(setError);

  return {
    loadProposals: fromPromise(async () => {
      try {
        const response = await axiosInstance.get("/load_proposals");
        const { open, closed } = response.data;

        return { active: open, closed };
      } catch (error) {
        console.error("Error loading proposals:", error);
        throw error;
      }
    }),
    loadProposal: fromPromise(async ({ input }) => {
      try {
        const { proposalId } = input;
        const response = await axiosInstance.get("/load_proposal", {
          params: { proposal_id: proposalId },
        });
        return { proposal: response.data };
      } catch (error) {
        console.error("Error loading proposal:", error);
        throw error;
      }
    }),
    vote: fromPromise(async ({ input }) => {
      try {
        const { proposalId, optionId } = input;
        const response = await axiosInstance.post("/vote", {
          proposal_id: proposalId,
          option_id: optionId,
        });
        return response.data;
      } catch (error) {
        console.error("Error voting:", error);
        throw error;
      }
    }),
  };
};
