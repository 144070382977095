import React, {
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { AuthContext } from "../../AuthWrapper/AuthWrapper";
import { useErrorContext } from "../../context/ErrorContext";
import styles from "./Ads.module.css";

const Ads = () => {
  const [error, setError] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);
  const [adData, setAdData] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const { axiosInstance } = useErrorContext();
  const { state } = useContext(AuthContext);

  useEffect(() => {
    const loadAdData = async () => {
      try {
        if (state.context?.Ads) {
          const resolvedAds = await state.context.Ads;
          setAdData(resolvedAds);
          // setIsLoading(false);
        }
      } catch (err) {
        console.error("Failed to load ad data:", err);
        setError(err);
        // setIsLoading(false);
      }
    };

    loadAdData();
  }, [state.context?.Ads]);

  // Process image only when adData.img changes
  useEffect(() => {
    if (adData?.img) {
      const convertBase64ToImageUrl = (base64String) => {
        const imageData = atob(base64String);
        const arrayBuffer = new ArrayBuffer(imageData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < imageData.length; i++) {
          uint8Array[i] = imageData.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: "image/jpeg" });
        return URL.createObjectURL(blob);
      };

      const url = convertBase64ToImageUrl(adData.img);
      setImageUrl(url);

      // Clean up the URL when component unmounts or when adData.img changes
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [adData?.img]);

  const handleAdClick = useCallback(async () => {
    try {
      if (adData?.id) {
        await axiosInstance.post(
          `ad_click?ad_id=${adData.id}&source=dashboard`
        );
      }
    } catch (err) {
      console.error("Failed to track ad click:", err);
    }
    if (adData?.url) {
      window.open(adData.url, "_blank", "noopener");
    }
  }, [adData?.id, adData?.url, axiosInstance]);

  if (error) {
    return <div className={`${styles.adsWrapper} ${styles.error}`}></div>;
  }

  if (adData?.img || adData?.text) {
    return (
      <div className={styles.gradient} onClick={handleAdClick}>
        <div className={styles.adsWrapper}>
          {imageUrl && (
            <img
              src={imageUrl}
              alt={adData.text || "Advertisement"}
              className={styles.adImage}
            />
          )}
          <div className={styles.textDiv}>{adData.text}</div>
        </div>
      </div>
    );
  }

  return null;
};

export default React.memo(Ads);
