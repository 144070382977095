import { useMachine } from "@xstate/react";
import { createContext, useContext, useEffect } from "react";
import { chatbotMachine } from "../xstate/chatbotXstate";
import { fromPromise } from "xstate";
import { createAxiosInstance } from "../utils/axiosInstance";
import { API_CONFIG, CHATBOT_PERSIST_KEY } from "../constants/constants";
import { useErrorContext } from "./ErrorContext";
// API service functions using axios
const createApiServices = (axiosInstance) => ({
  authenticatedApi: {
    sendMessage: fromPromise(async ({ input }) => {
      const result = await axiosInstance.get(
        `/proxy_llm_query?prompt=${encodeURIComponent(input?.text)}`
      );
      return { ...result.data };
    }),
    getMessageDetails: fromPromise(async ({ input }) => {
      const result = await axiosInstance.get(
        `/proxy_llm_data?query_id=${input.context.selectedMessageId}`
      );
      return { ...result.data };
    }),
  },
  unauthenticatedApi: {
    sendMessage: fromPromise(async ({ input }) => {
      try {
        const result = await axiosInstance.get("/public_llm_query", {
          params: {
            prompt: input.text,
          },
          headers: {
            "X-Wallet-Address": input.context.authState.wallet_address,
          },
        });
        return { ...result.data };
      } catch (error) {
        console.log("[sendMessage] Request failed:", error.message);
        throw new Error("API request failed");
      }
    }),
    getMessageDetails: fromPromise(async ({ input }) => {
      try {
        const result = await axiosInstance.get("/public_llm_data", {
          params: {
            query_id: input.context.selectedMessageId,
          },
          headers: {
            "X-Wallet-Address": input.context.authState.wallet_address,
          },
        });
        console.log(
          `[fetchVisualizationData] Request successful for responseId: ${input.context.selectedMessageId}`
        );
        return result.data;
      } catch (error) {
        console.log("[fetchVisualizationData] Request failed:", error.message);
        throw new Error("Failed to fetch visualization data");
      }
    }),
  },
});

const ChatBoxContext = createContext();

export const useChatBoxContext = () => useContext(ChatBoxContext);

export default function ChatBoxProvider({ children }) {
  const { setError, limitExceededModalOpen } = useErrorContext();

  const axiosInstance = createAxiosInstance(
    setError,
    limitExceededModalOpen,
    API_CONFIG.BASE_URL
  );

  const apiServices = createApiServices(axiosInstance);
  const [state, send, service] = useMachine(
    chatbotMachine.provide({
      actors: {
        fetchSubscriptions: fromPromise(async ({ input }) => {
          console.log("input", input.context);
          if (!input.context.authState.accessToken) return {};
          try {
            const response = await axiosInstance.get(`/get-subscriptions`);
            const result = response.data;

            return { result };
          } catch (error) {
            // setError(error.response?.data?.detail);
            throw new Error(error?.response?.data?.detail);
          }
        }),
        sendAuthenticatedMessage: apiServices.authenticatedApi.sendMessage,
        sendUnauthenticatedMessage: apiServices.unauthenticatedApi.sendMessage,
        getAuthenticatedMessageDetails:
          apiServices.authenticatedApi.getMessageDetails,
        getUnauthenticatedMessageDetails:
          apiServices.unauthenticatedApi.getMessageDetails,
      },
    })
    // {
    //   snapshot: (() => {
    //     const savedState = localStorage.getItem(CHATBOT_PERSIST_KEY);
    //     if (!savedState) return undefined;

    //     const parsedState = JSON.parse(savedState);
    //     return parsedState;
    //   })(),
    // }
  );

  useEffect(() => {
    const subscription = service.subscribe((state) => {
      // Create a deep copy of the state
      const stateToSave = JSON.parse(JSON.stringify(state));

      // Set messages to empty array when saving to localStorage
      if (stateToSave.context && stateToSave.context.messages) {
        stateToSave.context.messages = [];
        stateToSave.context.currentResponse = null;
        stateToSave.context.selectedMessageId = null;
        stateToSave.context.error = null;
      }

      localStorage.setItem(CHATBOT_PERSIST_KEY, JSON.stringify(stateToSave));
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [service]);

  return (
    <ChatBoxContext.Provider value={{ state, send }}>
      {children}
    </ChatBoxContext.Provider>
  );
}
