import React, { useState, useRef, useEffect } from "react";

function ChatInput({ onSendMessage, isLoading, typingComplete, disabled }) {
  const [input, setInput] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  // Focus input field when typing is complete
  useEffect(() => {
    if (typingComplete) {
      const timer = setTimeout(() => {
        inputRef.current?.focus();
        inputRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [typingComplete]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim() && !isLoading) {
      onSendMessage(input.trim());
      setInput("");
    }
  };

  return (
    <form className="chat-input" onSubmit={handleSubmit}>
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Type your message..."
        disabled={isLoading || disabled}
        ref={inputRef}
      />
      <button type="submit" disabled={isLoading || !input.trim() || disabled}>
        Send
      </button>
    </form>
  );
}

export default ChatInput;
