import React, { createContext, useContext, useState } from 'react';
import { useMachine } from '@xstate/react';
import { daoMachine, createDaoService } from '../xstate/daoXstate';

const DaoContext = createContext({});

export const DaoProvider = ({ children }) => {
  const [error, setError] = useState(null);
  
  const [daoService, send] = useMachine(daoMachine.provide({
    actors: createDaoService(setError),
  }))

  const isFetchingProposals = daoService.matches('loading')
  const isSelectingProposal = daoService.matches('loadingProposal')
  const isVoting = daoService.matches('voting')

  return (
    <DaoContext.Provider value={{ daoService, send, error, isFetchingProposals, isSelectingProposal, isVoting }}>
      {children}
    </DaoContext.Provider>
  );
};

export const useDao = () => useContext(DaoContext);

export default DaoProvider; 