import { useDisconnect, useWeb3ModalAccount } from "@web3modal/ethers/react";
import { useMachine } from "@xstate/react";
import React, { createContext, useEffect, useRef } from "react";
import { catchError, EMPTY, map } from "rxjs";
import { webSocket } from "rxjs/webSocket";
import { fromPromise, assign } from "xstate";
import { BASE_URL_WEBSOCKET } from "../constants/constants";
import { useSignMessage } from "../constants/getContracts";
import { useErrorContext } from "../context/ErrorContext";
import { authMachine } from "../xstate/authXstate";
import useIpLookup from "./useIpLookup";
import {
  PERSIST_KEY,
  TIME_TO_REFETCH_DASHBOARD_STATE,
} from "../constants/constants";
import { useReferral } from "./RefferalWrapper";
import { useChatBoxContext } from "../context/chatbotContext";
export const AuthContext = createContext(null);

const AuthWrapper = ({ children }) => {
  const socketRef = useRef(null);
  const { isConnected, address } = useWeb3ModalAccount();
  const ipAddress = useIpLookup();
  const signMessage = useSignMessage();
  const { disconnect } = useDisconnect();
  const { axiosInstance, clearError, setError } = useErrorContext();
  const { state: referralState, send: referralSend } = useReferral();
  const { state: chatbotState, send: chatbotSend } = useChatBoxContext();

  const [state, send, service] = useMachine(
    authMachine.provide({
      actors: {
        fetchDashboardState: fromPromise(async ({ input }) => {
          const response = await axiosInstance.get("/public_ds");
          const result = response.data;
          return {
            result,
          };
        }),

        fetchModelsData: fromPromise(async () => {
          const response = await axiosInstance.get("/public_ms");
          const result = response.data;
          return { result };
        }),

        fetchStrategyData: fromPromise(async () => {
          const response = await axiosInstance.get("/strategy_state");
          const result = response.data;

          return { result };
        }),

        fetchModelRecent: fromPromise(async ({ input }) => {
          const response = await axiosInstance.get("/model_recent", {
            params: {
              c_id: input.context?.selectedHistoricalModel,
              tf: input.context.modelStateFilter?.tf,
            },
          });
          const result = response.data;
          return { result };
        }),

        fetchStrategyHistory: fromPromise(async ({ input }) => {
          const response = await axiosInstance.get("/strategy_history", {
            params: {
              c_id: input.context?.selectedHistoricalModel,
              p_id: input.context?.selectedIds?.strategy,
              tf: input.context?.strategyStateFilter?.tf,
              start_date: input.context.strategyStateFilter?.start_date,
              threshold: input.context.strategyStateFilter?.threshold,
              size: input.context.strategyStateFilter?.size,
              allow_short: input.context.strategyStateFilter?.allow_short,
            },
          });
          const result = response.data;
          return { result };
        }),

        signInService: fromPromise(async ({ input }) => {
          try {
            const response = await axiosInstance.post("/login");
            const { access_token, master_key } = response?.data;
            return {
              accessToken: access_token,
              masterKey: master_key,
              ip_address: input.ip_address,
              wallet_address: input.wallet_address,
              signature: input.signature,
            };
          } catch (error) {
            throw new Error(
              JSON.stringify({
                ip_address: input.ip_address,
                wallet_address: input.wallet_address,
                signature: input.signature,
                error: error.response.data.detail,
              })
            );
          }
        }),

        verifyingSubscriptionInterval: fromPromise(async ({ input }) => {
          return new Promise((resolve, reject) => {
            const intervalId = setInterval(async () => {
              try {
                const response = await axiosInstance.get("/check-status");
                const { status, access_token } = response.data;

                if (status === "verified") {
                  clearInterval(intervalId);
                  resolve({ accessToken: access_token, status }); // Resolve the promise
                } else if (status !== "checking") {
                  clearInterval(intervalId);
                  reject(new Error("Unexpected status")); // Handle unexpected statuses
                }
              } catch (error) {
                setError(error.response?.data?.detail);

                clearInterval(intervalId);
                reject(new Error(error?.response?.data?.detail)); // Reject on error
              }
            }, 10000);
          });
        }),
        logoutService: fromPromise(async () => {
          try {
            const response = await axiosInstance.post("/logout");
          } catch (error) {
            setError(error.response?.data?.detail);
            throw new Error(error?.response?.data?.detail);
          }
        }),
        fetchModelHistory: fromPromise(async ({ input }) => {
          // debugger
          try {
            const response = await axiosInstance.get("/model_history", {
              params: {
                c_id: input.context?.selectedHistoricalModel,
                p_id: input.context?.selectedIds?.historical,
                tf: input.context.modelStateFilter?.tf,
              },
            });
            const result = response.data;
            return { result };
          } catch (error) {
            setError(error.response?.data?.detail);
            throw new Error(error?.response?.data?.detail);
          }
        }),

        fetchSubscriptions: fromPromise(async () => {
          try {
            const response = await axiosInstance.get(`/get-subscriptions`);
            const result = response.data;

            return { result };
          } catch (error) {
            setError(error.response?.data?.detail);
            throw new Error(error?.response?.data?.detail);
          }
        }),
        fetchDashboardDocs: fromPromise(async () => {
          try {
            const response = await axiosInstance.get("/dashboard_docs");
            return response?.data || null;
          } catch (error) {
            setError(error.message || "Failed to fetch dashboard docs");
            // throw new Error(error.message || "Failed to fetch dashboard docs");
          }
        }),

        fetchDashboardAds: fromPromise(async () => {
          try {
            const response = await axiosInstance.get("/ad?option=dashboard");
            return response?.data || null;
          } catch (error) {
            setError(error.message || "Failed to fetch ads");
            // throw new Error(error.message || "Failed to fetch ads");
          }
        }),
      },
      actions: {
        disconnectFromMetamask: async () => {
          disconnect();
          localStorage.removeItem(PERSIST_KEY);
          console.log("disconnectFromMetamask");
          chatbotSend({ type: "AUTH_LOGGED_OUT" });
        },
        clearError: () => {
          clearError();
        },

        initializeDocsAndAds: assign(({ spawn, self }) => {
          const docsActor = spawn("fetchDashboardDocs");
          const adsActor = spawn("fetchDashboardAds");

          docsActor.subscribe({
            next: (data) => {
              self.send({ type: "SET_DOCS", data: data?.output });
            },
            error: (error) => {
              setError(error);
            },
          });

          adsActor.subscribe({
            next: (data) => {
              self.send({ type: "SET_ADS", data: data?.output });
            },
            error: (error) => {
              console.log("error", error);
              setError(error);
            },
          });
        }),

        // initializeReferralData: assign(({ spawn, self }) => {
        //   referralSend({ type: "REFETCH_REFERRAL_CODE" });
        // }),
        refferalMetamaskSignal: assign(({ spawn, self }) => {
          referralSend({ type: "METAMASK_CONNECTED" });
        }),
        chatbotAuthSignal: assign(({ spawn, self, event }) => {
          chatbotSend({
            type: "AUTH_CHANGED",
            payload: {
              accessToken: event.output.accessToken,
              signature: event.output.signature,
              wallet_address: event.output.wallet_address,
            },
          });
        }),
        rehydrateChatbotData: assign(({ spawn, self, event, context }) => {
          chatbotSend({
            type: "AUTH_CHANGED",
            payload: {
              accessToken: context.accessToken,
              signature: context.signature,
              wallet_address: context.wallet_address,
            },
          });
        }),
      },
    }),
    {
      snapshot: (() => {
        const savedState = localStorage.getItem(PERSIST_KEY);
        if (!savedState) return undefined;

        const parsedState = JSON.parse(savedState);

        // Explicitly set modelState and dashboardState to null or empty state
        // parsedState.value = "idle";
        // parsedState.context.dashboardState = null;
        // parsedState.context.modelState = null;

        return parsedState;
      })(),
    }
  );

  useEffect(() => {
    // service.start()
    service.send({ type: "REFETCH_DASHBOARD_STATE" });
    const subscription = service.subscribe((state) => {
      localStorage.setItem(PERSIST_KEY, JSON.stringify(state));
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [service]);

  useEffect(() => {
    const signInUser = async () => {
      if (
        !state.context?.accessToken &&
        ipAddress &&
        isConnected &&
        (state.matches("home") || state.matches("idle")) &&
        !state.matches("waitingForSubscription")
      ) {
        try {
          const { signature } = await signMessage();
          send({
            type: "SIGN_IN",
            payload: {
              ip_address: ipAddress,
              wallet_address: address,
              signature,
            },
          });
        } catch (error) {
          console.error("Error signing message:", error);
        }
        return;
      }
    };

    signInUser();
  }, [state.value, isConnected]);

  useEffect(() => {
    const authState = state.context;
    const initializeWebSocket = () => {
      const accessToken = authState?.accessToken;
      const walletAddress = authState?.wallet_address;

      const socket$ = webSocket({
        url: `${BASE_URL_WEBSOCKET}?token=${encodeURIComponent(
          accessToken
        )}&walletAddress=${encodeURIComponent(walletAddress)}`,
        openObserver: {
          next: () => {
            console.log("WebSocket connected");
          },
        },
        errorObserver: {
          next: (error) => console.error("WebSocket error:", error),
        },
      });

      socketRef.current = socket$;

      const subscription = socket$
        .pipe(
          map((data) => {
            let parsedData;
            try {
              parsedData = typeof data === "string" ? JSON.parse(data) : data;
            } catch (error) {
              console.error("Error parsing WebSocket data:", error);
              return EMPTY;
            }

            send({
              type: "UPDATE_MODEL_STATE_FROM_SOCKET",
              payload: parsedData,
            });
            return data;
          }),
          catchError((err) => {
            console.error("Error processing WebSocket data:", err);
            return EMPTY;
          })
        )
        .subscribe();

      return () => {
        subscription.unsubscribe();
      };
    };
    if (state.context?.accessToken) {
      initializeWebSocket();
    }
    return () => {
      if (socketRef.current) {
        socketRef.current.complete();
      }
    };
  }, [state.context?.accessToken]);

  return (
    <AuthContext.Provider value={{ state, send }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthWrapper;
