import React from "react";
import styles from "./DaoComponents.module.css";
import { useDao } from "../../context/DaoContext";
import Loader from "../Loader/Loader";
const ClosedProposals = ({
  proposals = [],
  onSelectProposal,
  selectedProposalId,
}) => {
  const { isFetchingProposals } = useDao();
  if (!proposals || proposals.length === 0) {
    return (
      <div className={styles.proposalsContainer}>
        {isFetchingProposals && <Loader />}
        <h3 className={styles.containerTitle}>Closed Proposals</h3>
        <div className={styles.emptyState}>
          {isFetchingProposals ? (
            <Loader size="small" />
          ) : (
            "No closed proposals available"
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.proposalsContainer}>
      <h3
        className={styles.containerTitle}
      >{`Closed Proposals (${proposals.length})`}</h3>
      <div className={styles.proposalsList}>
        {proposals.map((proposal) => (
          <div
            key={proposal._id}
            className={`${styles.proposalItem} ${
              selectedProposalId === proposal._id ? styles.selected : ""
            }`}
            onClick={() => onSelectProposal(proposal._id)}
          >
            <div className={styles.proposalTitle}>{proposal.title}</div>
            <div className={styles.proposalTime}>Closed</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClosedProposals;
