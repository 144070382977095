import React from 'react';

function TableComponent({ data }) {
  if (!data.type || !data.data) return null;

  const formatNumber = (num) => {
    if (typeof num !== 'number') return num;

    // Different rounding based on price magnitude
    if (num >= 10) return num.toFixed(2);
    if (num >= 1) return num.toFixed(3);
    return num.toFixed(4);
  };

  const formatPercentage = (num) => {
    if (typeof num !== 'number') return num;
    return `${(num * 100).toFixed(2)}%`;
  };

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString();
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${month}-${day}`;
  };

  const getZScoreClass = (value) => {
    if (value > 1.5) return 'high';
    if (value < -1.5) return 'low';
    return 'medium';
  };

  const formatZScore = (num) => {
    if (typeof num !== 'number') return num;
    return num.toFixed(2); // Always round z-scores to 2 decimal places
  };

  const renderStateTable = (data) => {
    const assets = Object.keys(data);

    return (
      <table className='data-table state-table'>
        <thead>
          <tr>
            <th>Asset</th>
            <th>Current Price</th>
            <th>Predicted Price</th>
            <th>Z-Score</th>
            <th>Long Below</th>
            <th>Short Above</th>
          </tr>
        </thead>
        <tbody>
          {assets.map((asset) => {
            const assetData = data[asset];
            return (
              <tr key={asset} className={assetData.z_residual > 1 ? 'highlight-row' : ''}>
                <td className='asset-name'>{asset}</td>
                <td className='price'>${formatNumber(assetData.close)}</td>
                <td className='price'>${formatNumber(assetData.pred_price)}</td>

                <td className={`z-score ${getZScoreClass(assetData.z_residual)}`}>{formatZScore(assetData.z_residual)}</td>
                <td className='price'>${formatNumber(assetData.long_below)}</td>
                <td className='price'>${formatNumber(assetData.short_above)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };
  const renderStateSummaryTable = (data) => {
    const assets = Object.keys(data);

    return (
      <table className='data-table state-table'>
        <thead>
          <tr>
            <th>Asset</th>
            <th>Current Price</th>
            <th>Predicted Price</th>
            <th>Z-Score</th>
          </tr>
        </thead>
        <tbody>
          {assets.map((asset) => {
            const assetData = data[asset];
            return (
              <tr key={asset} className={assetData.z_residual > 1 ? 'highlight-row' : ''}>
                <td className='asset-name'>{asset}</td>
                <td className='price'>${formatNumber(assetData.close)}</td>
                <td className='price'>${formatNumber(assetData.pred_price)}</td>
                <td className={`z-score ${getZScoreClass(assetData.z_residual)}`}>{formatZScore(assetData.z_residual)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };
  const renderHistoryTable = (data) => {
    const assets = Object.keys(data);

    // Get all unique dates across all assets
    const allDates = new Set();
    assets.forEach((asset) => {
      Object.keys(data[asset]).forEach((date) => {
        allDates.add(date);
      });
    });

    // Sort dates in ascending order (oldest first)
    const sortedDates = Array.from(allDates).sort((a, b) => new Date(a) - new Date(b));

    return (
      <table className='data-table history-table'>
        <thead>
          <tr>
            <th>Asset</th>
            {sortedDates.map((date) => (
              <th key={date}>{formatDate(date)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {assets.map((asset) => (
            <tr key={asset}>
              <td className='asset-name'>{asset}</td>
              {sortedDates.map((date) => {
                const value = data[asset][date];
                return (
                  <td key={`${asset}-${date}`} className={`z-score ${value ? getZScoreClass(value) : ''}`}>
                    {value ? formatZScore(value) : '-'}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderTable = () => {
    switch (data.type) {
      case 'state':
        return renderStateTable(data.data);
      case 'history':
        return renderHistoryTable(data.data);
      case 'state_summary':
        return renderStateSummaryTable(data.data);
      case 'history_summary':
        return renderHistoryTable(data.data);
      default:
        return null;
    }
  };

  return <div className='table-wrapper rounded-table'>{renderTable()}</div>;
}

export default TableComponent;
