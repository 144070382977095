import { useLocation, Link } from "react-router-dom";
import styles from "./Sidebar.module.css";
import WalletButtons from "../WalletButtons/WalletButtons";
import { useEffect } from "react";
import Ads from "../Ads/Ads";
import SocialButtons from "../SocialButtons/SocialButtons";
import { routeConfig, getSidebarMenu } from "../../config/routeConfig";

const menuItems = Object.entries(getSidebarMenu(routeConfig)).map(
  ([key, route]) => ({
    src: route.icon,
    label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter of the key for the label
    path: route.path,
    status: route.enabled,
    externalLink: route.isExternal ? route.path : undefined,
  })
);

export default function Sidebar({ onClick }) {
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    const preloadImages = menuItems
      .filter((item) => item.status)
      .flatMap((item) => [item.src, item.src.replace(".svg", "S.svg")]);

    preloadImages.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div>
          <div className={styles.logo} onClick={onClick}>
            <img src="images/yaiLogo.svg" alt="Logo" />
          </div>
          <div className={styles.menu}>
            {menuItems.map((item) =>
              item.status ? (
                item.externalLink ? (
                  <a
                    href={item.externalLink}
                    key={item.label}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.menuItem}
                  >
                    <div className={styles.iconContainer}>
                      <img src={item.src} alt={item.label} />
                    </div>
                    <div className={styles.labelContainer}>
                      <div className={styles.label}>{item.label}</div>
                    </div>
                  </a>
                ) : (
                  <Link
                    to={item.path}
                    key={item.label}
                    className={`${styles.menuItem} ${
                      currentPath === item.path ? styles.selected : ""
                    }`}
                  >
                    <div className={styles.iconContainer}>
                      <img
                        src={
                          currentPath === item.path
                            ? item.src.replace(".svg", "S.svg")
                            : item.src
                        }
                        alt={item.label}
                      />
                    </div>
                    <div className={styles.labelContainer}>
                      <div className={styles.label}>{item.label}</div>
                    </div>
                  </Link>
                )
              ) : (
                <div
                  key={item.label}
                  className={`${styles.menuItem} ${styles.menuItemDisabled}`}
                >
                  <div className={styles.iconContainer}>
                    <img src={item.src} alt={item.label} />
                  </div>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>
                      {item.label}{" "}
                      <span className={styles.comingSoon}>Coming Soon</span>
                    </div>
                  </div>
                </div>
              )
            )}
            <Ads />
            <SocialButtons />
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.bottomInner}>
            <WalletButtons />
          </div>
        </div>
      </div>
    </div>
  );
}
