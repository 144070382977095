import React from 'react';
import Plot from 'react-plotly.js';

function PlotComponent({ fig }) {
  if (!fig) return null;
  // Only override the styling-related layout properties
  const unifiedLayout = {
    ...fig.layout,
    paper_bgcolor: 'rgba(0,0,0,0)',
    plot_bgcolor: 'rgba(0,0,0,0)',
    font: { color: 'white' },
    modebar: {
      bgcolor: 'rgba(0,0,0,0)',
      color: 'white'
    },
    margin: { t: 50, r: 30, l: 30, b: 30 }, // Tighter margins
    autosize: true, // Ensure plot resizes with container
    xaxis: {
      ...fig.layout.xaxis,
      showgrid: false
    },
    yaxis: {
      ...fig.layout.yaxis,
      showgrid: false
    }
  };
  return (
    <div className="plot-wrapper">
      <Plot
        data={fig.data}
        layout={unifiedLayout}
        config={{
          responsive: true,
          displayModeBar: true,
          displaylogo: false,
          modeBarButtonsToRemove: [
            'lasso2d',
            'select2d',
            'autoScale2d'
          ]
        }}
        className="plot"
        useResizeHandler={true}
        style={{
          width: '100%',
          height: '100%'
        }}
      />
    </div>
  );
}

export default PlotComponent; 