import React, { useContext, useEffect } from "react";
import MdDocs from "../components/MdDocs/MdDocs";
import { AuthContext } from "../AuthWrapper/AuthWrapper";

const Docs = () => {
  const { state, send } = useContext(AuthContext);

  useEffect(() => {
    if (
      state.matches("model") ||
      state.matches("home") ||
      state.matches("strategy") ||
      state.matches("apiKey") ||
      state.matches("chat") ||
      state.matches("dao")
    )
      send({ type: "CHECK_ROUTE" });
  }, [send]);
  return (
    <div style={{ color: "white", width: "100%" }}>
      <MdDocs />
    </div>
  );
};

export default Docs;
