import React, { useState, useRef, useEffect } from "react";

function DefaultQuestions({
  DEFAULT_QUESTIONS,
  onQuestionClick,
  isLoading,
  isTypingComplete,
  disabled,
}) {
  const [showAll, setShowAll] = useState(false);
  const [randomizedQuestions, setRandomizedQuestions] = useState([]);
  const timeoutRef = useRef(null);
  const prevTypingCompleteRef = useRef(isTypingComplete);
  const isInitialMount = useRef(true);

  useEffect(() => {
    // Shuffle on initial mount
    if (isInitialMount.current) {
      const shuffled = [...DEFAULT_QUESTIONS].sort(() => Math.random() - 0.5);
      setRandomizedQuestions(shuffled);
      isInitialMount.current = false;
      return;
    }

    // Shuffle after response
    if (isTypingComplete && !prevTypingCompleteRef.current) {
      const shuffled = [...DEFAULT_QUESTIONS].sort(() => Math.random() - 0.5);
      setRandomizedQuestions(shuffled);
    }
    prevTypingCompleteRef.current = isTypingComplete;
  }, [DEFAULT_QUESTIONS, isTypingComplete]);

  const visibleQuestions = randomizedQuestions.slice(0, 2);
  const hiddenQuestions = randomizedQuestions.slice(2);

  const handleMouseEnter = () => {
    if (isLoading || !isTypingComplete) return;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setShowAll(true);
  };

  const handleMouseLeave = () => {
    if (isLoading || !isTypingComplete) return;

    timeoutRef.current = setTimeout(() => {
      setShowAll(false);
    }, 100);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isLoading || !isTypingComplete) {
      setShowAll(false);
    }
  }, [isLoading, isTypingComplete]);

  return (
    <div
      className="default-questions"
      onMouseEnter={disabled ? null : handleMouseEnter}
      onMouseLeave={disabled ? null : handleMouseLeave}
      style={{
        pointerEvents: isLoading || !isTypingComplete ? "none" : "auto",
      }}
    >
      {/* <div className='default-questions-label'>Try asking:</div> */}
      <div className="default-questions-subcontainer">
        {disabled
          ? null
          : visibleQuestions.map((question, index) => (
              <button
                disabled={isLoading}
                key={index}
                className="default-question-button"
                onClick={disabled ? null : () => onQuestionClick(question)}
          >
            {question}
          </button>
        ))}
        {disabled
          ? null
          : !showAll && randomizedQuestions.length > 2 && (
              <button
                className="default-question-button"
                onClick={disabled ? null : handleMouseEnter}
                style={{ opacity: 0.7 }}
                disabled={isLoading || !isTypingComplete}
          >
            +{randomizedQuestions.length - 2} more
          </button>
        )}
        <div
          className={`default-questions-visible ${
            showAll ? "" : "default-questions-hidden"
          }`}
        >
          {hiddenQuestions.map((question, index) => (
            <button
              disabled={isLoading || !isTypingComplete}
              key={`hidden-${index}`}
              className="default-question-button"
              onClick={disabled ? null : () => onQuestionClick(question)}
            >
              {question}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DefaultQuestions;
