import React from "react";
import { useNavigate } from "react-router-dom";
import { useErrorContext } from "../../context/ErrorContext.js";
import styles from "./LimitExceededModal.module.css";

const LimitExceededModal = () => {
  const navigate = useNavigate();
  const { limitExceededModalClose: closeModal } = useErrorContext();

  return (
    <div className={styles.modalOverlay} onClick={closeModal}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.title}>Usage Limit Reached</div>
        <div className={styles.content}>
          <p>
            You have reached your usage limit. Please upgrade your plan to
            continue using our services.
          </p>
        </div>
        <div className={styles.actions}>
          <div
            className={styles.accessButton}
            onClick={() => {
              closeModal();
              navigate("/access");
            }}
          >
            Go to Access Page
          </div>
        </div>
      </div>
    </div>
  );
};

export default LimitExceededModal;
