import React from "react";
import styles from "./Loader.module.css"; // Import CSS module

const MessageProcessing = () => {
  return (
    <div className={styles.typingIndicator}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default MessageProcessing;
