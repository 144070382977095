// // API Base URL
//export const BASE_URL = 'http://127.0.0.1:8000';
export const BASE_URL = "/api/"; //"http://127.0.0.1:8000"; //"http://127.0.0.1:8000"; //http://127.0.0.1:8000"; //"http://127.0.0.1:8000";
// export const BASE_URL_WEBSOCKET = '/ws/';
// export const BASE_URL_CRONE = 'http://127.0.0.1:8000';
// export const BASE_URL = '/api/';

export const API_CONFIG = {
  BASE_URL: "/api/", //"http://127.0.0.1:8000", //"http://127.0.0.1:8000", //"http://127.0.0.1:8000", //"http://127.0.0.1:8000", //"http://localhost:8000", //'/api/',
  DEFAULT_WALLET: "0x000000",
};
export const BASE_URL_WEBSOCKET = "/ws/"; //ws://localhost:8081';

export const statusCodes = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  LIMIT_EXCEEDED: 429,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
};
// Project IDs
export const PROJECT_IDS = ["BTC", "ETH", "SOL", "BNB", "WIF"];

// API Endpoints
export const API = {
  MODEL_STATE: `${BASE_URL}/model_state`,
};

export const HEATMAP_INITIAL = {
  BTC: { z_score: 0, residual: 0 },
  ETH: { z_score: 0, residual: 0 },
  SOL: { z_score: 0, residual: 0 },
  WIF: { z_score: 0, residual: 0 },
  BNB: { z_score: 0, residual: 0 },
};

export const PH_GENERAL_OVERVIEW = Array.from({ length: 9 }, () => ({
  label: "--",
  value: "0%",
  trend: "N/A",
}));
// Default selected option
export const DEFAULT_SELECTED_OPTION = "BTC";

// Custom color scale for heatmap
export const CUSTOM_HEATMAP_COLORS = [
  [0, "rgba(255,99,71,0.8)"],
  [0.1, "rgba(255,140,0,0.8)"],
  [0.2, "rgba(255,165,0,0.8)"],
  [0.3, "rgba(255,215,0,0.8)"],
  [0.4, "rgba(255,255,0,0.8)"],
  [0.5, "rgba(255,255,102,0.8)"],
  [0.6, "rgba(173,255,47,0.8)"],
  [0.7, "rgba(144,238,144,0.8)"],
  [0.8, "rgba(50,205,50,0.8)"],
  [0.9, "rgba(34,139,34,0.8)"],
  [1, "rgba(0,128,0,0.8)"],
];

export const MARKET_OVERVIEW_INIT = [
  {
    rank: "--",
    symbol: "--",
    currentPrice: "--",
    predictedPrice: "--",
    residual: "--",
  },
  {
    rank: "--",
    symbol: "--",
    currentPrice: "--",
    predictedPrice: "--",
    residual: "--",
  },
  {
    rank: "--",
    symbol: "--",
    currentPrice: "--",
    predictedPrice: "--",
    residual: "--",
  },
];

export const MARKET_OVERVIEW_HOME = [
  { key: "rank", label: "Rank", sortable: true },
  { key: "symbol", label: "Symbol", sortable: true },
  { key: "currentPrice", label: "Current Price", sortable: false },
  { key: "predictedPrice", label: "Predicted Price", sortable: false },
  { key: "residual", label: "Residual %", sortable: true },
];

export const MARKET_OVERVIEW_MODEL = [
  { key: "collectionName", label: "Model Name", sortable: false },
  { key: "residual", label: "Residual", sortable: true },
  { key: "r2_train", label: "R2 Train", sortable: true },
  { key: "mse_train", label: "MSE Train", sortable: true },
];

export const MODEL_OVERVIEW = [
  { key: "symbol", label: "Symbol", sortable: false },
  { key: "residual", label: "Residual", sortable: true },
  { key: "r2_train", label: "R2 Train", sortable: true },
  { key: "mse_train", label: "MSE Train", sortable: true },
];

export const STRATEGIES_MODEL = [
  { key: "collectionName", label: "Model Name", sortable: false },

  { key: "sharpe", label: "Sharpe", sortable: true },
  { key: "sortino", label: "Sortino", sortable: true },
  { key: "winrate", label: "Win Rate", sortable: true },
];

export const TRADES_STRATEGY = [
  { key: "timestamp", label: "Time", sortable: true },
  { key: "price", label: "Price", sortable: false },
  { key: "position_delta", label: "Position Change", sortable: false },
  { key: "position", label: "Position", sortable: false },
  { key: "side", label: "Side", sortable: false },
];

export const tokenAddresses = {
  Y: "0x477a3d269266994F15E9C43A8D9C0561C4928088",
};

export const MinTokenAmount = 0;
export const MinTokenAmountLayout = 50000;

export const EXTERNAL_LINKS = {
  X: "https://x.com/yai_erc",
  Telegram: "https://t.me/+BAgu_j2177kxMDZk",
  Docs: "https://docs.yoracle.ai/",
  Youtube: "https://www.youtube.com/@YAIoracle",
};
export const Fixed_Amount_Upto_Below_Threshold = 3;
export const Fixed_Amount_Upto_Above_Threshold = 2;
export const Fixed_Amount_threshold = 10;

export const AMOUNT_NEEDED_FOR_TEMPORARY_SUBSCRIPTION = 4000;

export const SUBSCRIPTION_ADDRESS =
  "0xE1deE4371addc2E07e24D8cB875b3d64d0aaEA98";
export const SUBSCRIPTION_AMOUNT = "4000";
export const PERMANENT_AMOUNT = "50000";

// src/constants/constants.js

export const PERFORMANCE_HEATMAP_INITIAL = {
  cex: {
    BTC: {
      low: {},
      high: {},
    },
    ETH: {
      low: {},
      high: {},
    },
    SOL: {
      low: {},
      high: {},
    },
    ADA: {
      low: {},
      high: {},
    },
    DOT: {
      low: {},
      high: {},
    },
    BNB: {
      low: {},
      high: {},
    },
  },
  oracle: {
    BTC: {
      low: {},
      high: {},
    },
    ETH: {
      low: {},
      high: {},
    },
    SOL: {
      low: {},
      high: {},
    },
    ADA: {
      low: {},
      high: {},
    },
    DOT: {
      low: {},
      high: {},
    },
    BNB: {
      low: {},
      high: {},
    },
  },
  gnew: {
    BTC: {
      low: {},
      high: {},
    },
    ETH: {
      low: {},
      high: {},
    },
    SOL: {
      low: {},
      high: {},
    },
    ADA: {
      low: {},
      high: {},
    },
    DOT: {
      low: {},
      high: {},
    },
    BNB: {
      low: {},
      high: {},
    },
  },
  orderbook: {
    BTC: {
      low: {},
      high: {},
    },
    ETH: {
      low: {},
      high: {},
    },
    SOL: {
      low: {},
      high: {},
    },
    ADA: {
      low: {},
      high: {},
    },
    DOT: {
      low: {},
      high: {},
    },
    BNB: {
      low: {},
      high: {},
    },
  },
  balance: {
    BTC: {
      low: {},
      high: {},
    },
    ETH: {
      low: {},
      high: {},
    },
    SOL: {
      low: {},
      high: {},
    },
    ADA: {
      low: {},
      high: {},
    },
    DOT: {
      low: {},
      high: {},
    },
    BNB: {
      low: {},
      high: {},
    },
  },
  tradfi: {
    BTC: {
      low: {},
      high: {},
    },
    ETH: {
      low: {},
      high: {},
    },
    SOL: {
      low: {},
      high: {},
    },
    ADA: {
      low: {},
      high: {},
    },
    DOT: {
      low: {},
      high: {},
    },
    BNB: {
      low: {},
      high: {},
    },
  },
};

export const METRICS = [
  "sharpe",
  "sortino",
  "winrate",
  "1m_return",
  "1q_return",
];

export const DEFAULT_MODEL = "oracle";

export const PERSIST_KEY = "authXState";

export const TIME_TO_REFETCH_DASHBOARD_STATE = 2 * 60 * 60 * 1000; // 2 hours in milliseconds

export const DEFAULT_QUESTIONS = [
  "What's the current state of ETH?",
  "Show me the latest predictions for BTC",
  "What are the most overbought assets?",
  "Which assets are oversold right now?",
  "Compare BTC and ETH valuations over the last week",
  "Summarize the current state of the market",
  "Where would i long or short BTC right now.",
  "How has the market evolved over the last week?",
];

export const VALID_SYMBOLS = [
  "BTC",
  "ETH",
  "SOL",
  "BNB",
  "WIF",
  "DOGE",
  "DOT",
  "ADA",
  "LINK",
  "XRP",
];

export const MOCK_LLM_QUERY_RESPONSE = {
  response_id: "0x000000_1741254460.667676",
  response_txt:
    "ETH is currently slightly overvalued, closing at about $1,800. It has a potential support zone between $1,875 and $1,575, which could be of interest for buying. On the higher end, there's a resistance zone from $2,340 to $2,640. Overall, it's a bit pricey right now.",
};

export const MOCK_LLM_DATA_RESPONSE = {
  data: {
    ETH: {
      residual: -7.86,
      z_residual: -1.2374,
      close: 2288.3,
      pred_price: 2108.3633,
      pred_return: -0.1026,
      return: -0.024,
      r2_train: 0.46874325773741,
      mse_train: 0.003963326075640921,
      mean_residual: 0.010838,
      std_residual: 0.072308,
      timestamp: "2025-03-06 09:47:34",
      long_below: 1879.69,
      short_above: 2337.04,
    },
  },
  fig: {
    data: [
      {
        base: -3,
        hoverinfo: "skip",
        marker: {
          color: "rgba(255, 68, 68, 0.3)",
          line: {
            color: "rgba(255, 255, 255, 0.3)",
            width: 1,
          },
        },
        orientation: "h",
        showlegend: false,
        x: [1.5],
        y: ["ETH"],
        type: "bar",
      },
      {
        base: -1.5,
        hoverinfo: "skip",
        marker: {
          color: "rgba(255, 153, 0, 0.3)",
          line: {
            color: "rgba(255, 255, 255, 0.3)",
            width: 1,
          },
        },
        orientation: "h",
        showlegend: false,
        x: [3],
        y: ["ETH"],
        type: "bar",
      },
      {
        base: 1.5,
        hoverinfo: "skip",
        marker: {
          color: "rgba(26, 255, 0, 0.3)",
          line: {
            color: "rgba(255, 255, 255, 0.3)",
            width: 1,
          },
        },
        orientation: "h",
        showlegend: false,
        x: [1.5],
        y: ["ETH"],
        type: "bar",
      },
      {
        hovertemplate: "Z-score: %{x:.3f}<br>Valuation: Neutral<extra></extra>",
        marker: {
          color: "yellow",
          line: {
            color: "white",
            width: 1,
          },
          size: 12,
          symbol: "diamond",
        },
        mode: "markers",
        name: "ETH",
        showlegend: false,
        x: [-1.2374],
        y: ["ETH"],
        type: "scatter",
      },
    ],
    layout: {
      template: {
        data: {
          histogram2dcontour: [
            {
              type: "histogram2dcontour",
              colorbar: {
                outlinewidth: 0,
                ticks: "",
              },
              colorscale: [
                [0, "#0d0887"],
                [0.1111111111111111, "#46039f"],
                [0.2222222222222222, "#7201a8"],
                [0.3333333333333333, "#9c179e"],
                [0.4444444444444444, "#bd3786"],
                [0.5555555555555556, "#d8576b"],
                [0.6666666666666666, "#ed7953"],
                [0.7777777777777778, "#fb9f3a"],
                [0.8888888888888888, "#fdca26"],
                [1, "#f0f921"],
              ],
            },
          ],
          choropleth: [
            {
              type: "choropleth",
              colorbar: {
                outlinewidth: 0,
                ticks: "",
              },
            },
          ],
          histogram2d: [
            {
              type: "histogram2d",
              colorbar: {
                outlinewidth: 0,
                ticks: "",
              },
              colorscale: [
                [0, "#0d0887"],
                [0.1111111111111111, "#46039f"],
                [0.2222222222222222, "#7201a8"],
                [0.3333333333333333, "#9c179e"],
                [0.4444444444444444, "#bd3786"],
                [0.5555555555555556, "#d8576b"],
                [0.6666666666666666, "#ed7953"],
                [0.7777777777777778, "#fb9f3a"],
                [0.8888888888888888, "#fdca26"],
                [1, "#f0f921"],
              ],
            },
          ],
          heatmap: [
            {
              type: "heatmap",
              colorbar: {
                outlinewidth: 0,
                ticks: "",
              },
              colorscale: [
                [0, "#0d0887"],
                [0.1111111111111111, "#46039f"],
                [0.2222222222222222, "#7201a8"],
                [0.3333333333333333, "#9c179e"],
                [0.4444444444444444, "#bd3786"],
                [0.5555555555555556, "#d8576b"],
                [0.6666666666666666, "#ed7953"],
                [0.7777777777777778, "#fb9f3a"],
                [0.8888888888888888, "#fdca26"],
                [1, "#f0f921"],
              ],
            },
          ],
          contourcarpet: [
            {
              type: "contourcarpet",
              colorbar: {
                outlinewidth: 0,
                ticks: "",
              },
            },
          ],
          contour: [
            {
              type: "contour",
              colorbar: {
                outlinewidth: 0,
                ticks: "",
              },
              colorscale: [
                [0, "#0d0887"],
                [0.1111111111111111, "#46039f"],
                [0.2222222222222222, "#7201a8"],
                [0.3333333333333333, "#9c179e"],
                [0.4444444444444444, "#bd3786"],
                [0.5555555555555556, "#d8576b"],
                [0.6666666666666666, "#ed7953"],
                [0.7777777777777778, "#fb9f3a"],
                [0.8888888888888888, "#fdca26"],
                [1, "#f0f921"],
              ],
            },
          ],
          surface: [
            {
              type: "surface",
              colorbar: {
                outlinewidth: 0,
                ticks: "",
              },
              colorscale: [
                [0, "#0d0887"],
                [0.1111111111111111, "#46039f"],
                [0.2222222222222222, "#7201a8"],
                [0.3333333333333333, "#9c179e"],
                [0.4444444444444444, "#bd3786"],
                [0.5555555555555556, "#d8576b"],
                [0.6666666666666666, "#ed7953"],
                [0.7777777777777778, "#fb9f3a"],
                [0.8888888888888888, "#fdca26"],
                [1, "#f0f921"],
              ],
            },
          ],
          mesh3d: [
            {
              type: "mesh3d",
              colorbar: {
                outlinewidth: 0,
                ticks: "",
              },
            },
          ],
          scatter: [
            {
              fillpattern: {
                fillmode: "overlay",
                size: 10,
                solidity: 0.2,
              },
              type: "scatter",
            },
          ],
          parcoords: [
            {
              type: "parcoords",
              line: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
            },
          ],
          scatterpolargl: [
            {
              type: "scatterpolargl",
              marker: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
            },
          ],
          bar: [
            {
              error_x: {
                color: "#2a3f5f",
              },
              error_y: {
                color: "#2a3f5f",
              },
              marker: {
                line: {
                  color: "#E5ECF6",
                  width: 0.5,
                },
                pattern: {
                  fillmode: "overlay",
                  size: 10,
                  solidity: 0.2,
                },
              },
              type: "bar",
            },
          ],
          scattergeo: [
            {
              type: "scattergeo",
              marker: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
            },
          ],
          scatterpolar: [
            {
              type: "scatterpolar",
              marker: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
            },
          ],
          histogram: [
            {
              marker: {
                pattern: {
                  fillmode: "overlay",
                  size: 10,
                  solidity: 0.2,
                },
              },
              type: "histogram",
            },
          ],
          scattergl: [
            {
              type: "scattergl",
              marker: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
            },
          ],
          scatter3d: [
            {
              type: "scatter3d",
              line: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
              marker: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
            },
          ],
          scattermap: [
            {
              type: "scattermap",
              marker: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
            },
          ],
          scattermapbox: [
            {
              type: "scattermapbox",
              marker: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
            },
          ],
          scatterternary: [
            {
              type: "scatterternary",
              marker: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
            },
          ],
          scattercarpet: [
            {
              type: "scattercarpet",
              marker: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
            },
          ],
          carpet: [
            {
              aaxis: {
                endlinecolor: "#2a3f5f",
                gridcolor: "white",
                linecolor: "white",
                minorgridcolor: "white",
                startlinecolor: "#2a3f5f",
              },
              baxis: {
                endlinecolor: "#2a3f5f",
                gridcolor: "white",
                linecolor: "white",
                minorgridcolor: "white",
                startlinecolor: "#2a3f5f",
              },
              type: "carpet",
            },
          ],
          table: [
            {
              cells: {
                fill: {
                  color: "#EBF0F8",
                },
                line: {
                  color: "white",
                },
              },
              header: {
                fill: {
                  color: "#C8D4E3",
                },
                line: {
                  color: "white",
                },
              },
              type: "table",
            },
          ],
          barpolar: [
            {
              marker: {
                line: {
                  color: "#E5ECF6",
                  width: 0.5,
                },
                pattern: {
                  fillmode: "overlay",
                  size: 10,
                  solidity: 0.2,
                },
              },
              type: "barpolar",
            },
          ],
          pie: [
            {
              automargin: true,
              type: "pie",
            },
          ],
        },
        layout: {
          autotypenumbers: "strict",
          colorway: [
            "#636efa",
            "#EF553B",
            "#00cc96",
            "#ab63fa",
            "#FFA15A",
            "#19d3f3",
            "#FF6692",
            "#B6E880",
            "#FF97FF",
            "#FECB52",
          ],
          font: {
            color: "#2a3f5f",
          },
          hovermode: "closest",
          hoverlabel: {
            align: "left",
          },
          paper_bgcolor: "white",
          plot_bgcolor: "#E5ECF6",
          polar: {
            bgcolor: "#E5ECF6",
            angularaxis: {
              gridcolor: "white",
              linecolor: "white",
              ticks: "",
            },
            radialaxis: {
              gridcolor: "white",
              linecolor: "white",
              ticks: "",
            },
          },
          ternary: {
            bgcolor: "#E5ECF6",
            aaxis: {
              gridcolor: "white",
              linecolor: "white",
              ticks: "",
            },
            baxis: {
              gridcolor: "white",
              linecolor: "white",
              ticks: "",
            },
            caxis: {
              gridcolor: "white",
              linecolor: "white",
              ticks: "",
            },
          },
          coloraxis: {
            colorbar: {
              outlinewidth: 0,
              ticks: "",
            },
          },
          colorscale: {
            sequential: [
              [0, "#0d0887"],
              [0.1111111111111111, "#46039f"],
              [0.2222222222222222, "#7201a8"],
              [0.3333333333333333, "#9c179e"],
              [0.4444444444444444, "#bd3786"],
              [0.5555555555555556, "#d8576b"],
              [0.6666666666666666, "#ed7953"],
              [0.7777777777777778, "#fb9f3a"],
              [0.8888888888888888, "#fdca26"],
              [1, "#f0f921"],
            ],
            sequentialminus: [
              [0, "#0d0887"],
              [0.1111111111111111, "#46039f"],
              [0.2222222222222222, "#7201a8"],
              [0.3333333333333333, "#9c179e"],
              [0.4444444444444444, "#bd3786"],
              [0.5555555555555556, "#d8576b"],
              [0.6666666666666666, "#ed7953"],
              [0.7777777777777778, "#fb9f3a"],
              [0.8888888888888888, "#fdca26"],
              [1, "#f0f921"],
            ],
            diverging: [
              [0, "#8e0152"],
              [0.1, "#c51b7d"],
              [0.2, "#de77ae"],
              [0.3, "#f1b6da"],
              [0.4, "#fde0ef"],
              [0.5, "#f7f7f7"],
              [0.6, "#e6f5d0"],
              [0.7, "#b8e186"],
              [0.8, "#7fbc41"],
              [0.9, "#4d9221"],
              [1, "#276419"],
            ],
          },
          xaxis: {
            gridcolor: "white",
            linecolor: "white",
            ticks: "",
            title: {
              standoff: 15,
            },
            zerolinecolor: "white",
            automargin: true,
            zerolinewidth: 2,
          },
          yaxis: {
            gridcolor: "white",
            linecolor: "white",
            ticks: "",
            title: {
              standoff: 15,
            },
            zerolinecolor: "white",
            automargin: true,
            zerolinewidth: 2,
          },
          scene: {
            xaxis: {
              backgroundcolor: "#E5ECF6",
              gridcolor: "white",
              linecolor: "white",
              showbackground: true,
              ticks: "",
              zerolinecolor: "white",
              gridwidth: 2,
            },
            yaxis: {
              backgroundcolor: "#E5ECF6",
              gridcolor: "white",
              linecolor: "white",
              showbackground: true,
              ticks: "",
              zerolinecolor: "white",
              gridwidth: 2,
            },
            zaxis: {
              backgroundcolor: "#E5ECF6",
              gridcolor: "white",
              linecolor: "white",
              showbackground: true,
              ticks: "",
              zerolinecolor: "white",
              gridwidth: 2,
            },
          },
          shapedefaults: {
            line: {
              color: "#2a3f5f",
            },
          },
          annotationdefaults: {
            arrowcolor: "#2a3f5f",
            arrowhead: 0,
            arrowwidth: 1,
          },
          geo: {
            bgcolor: "white",
            landcolor: "#E5ECF6",
            subunitcolor: "white",
            showland: true,
            showlakes: true,
            lakecolor: "white",
          },
          title: {
            x: 0.05,
          },
          mapbox: {
            style: "light",
          },
        },
      },
      shapes: [
        {
          line: {
            color: "rgba(255, 255, 255, 0.3)",
            dash: "dash",
            width: 1,
          },
          type: "line",
          x0: -1.5,
          x1: -1.5,
          xref: "x",
          y0: 0,
          y1: 1,
          yref: "y domain",
        },
        {
          line: {
            color: "rgba(255, 255, 255, 0.3)",
            dash: "dash",
            width: 1,
          },
          type: "line",
          x0: 1.5,
          x1: 1.5,
          xref: "x",
          y0: 0,
          y1: 1,
          yref: "y domain",
        },
      ],
      xaxis: {
        range: [-3, 3],
        title: {
          text: "Valuation Scale (z-score)",
        },
        gridcolor: "rgba(26, 255, 0, 0.1)",
        zerolinecolor: "rgba(26, 255, 0, 0.2)",
      },
      yaxis: {
        title: {
          text: "Assets",
        },
        gridcolor: "rgba(26, 255, 0, 0.1)",
        zerolinecolor: "rgba(26, 255, 0, 0.2)",
      },
      font: {
        color: "white",
      },
      margin: {
        t: 50,
        r: 25,
        l: 100,
        b: 25,
      },
      title: {
        text: "Asset Valuations",
      },
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      barmode: "stack",
    },
  },
  type: "state",
};

export const ENABLE_REFERRAL = false;
export const CHATBOT_PERSIST_KEY = "chatbot-XState";

export const VAULT_URL =
  "https://app.hyperliquid.xyz/vaults/0xed8878c9b4ed17d063a92ad8c454661fa1e2bac9";
