import React from "react";
import styles from "./accessRestriction.module.css";
import {
  MinTokenAmountLayout,
  EXTERNAL_LINKS as SOCIAL_MEDIA_LINKS,
} from "../../constants/constants";

const AccessRestriction = ({ onConnectWallet }) => {
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.title}>Access Restricted</div>
        <div className={styles.subtitle}>
          You need at least{" "}
          <strong>{MinTokenAmountLayout.toLocaleString()} $YAI</strong> to
          access the DAO portal.
        </div>

        <div className={styles.socialMedia}>
          <a
            href={SOCIAL_MEDIA_LINKS.X}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{
                height: "30px",
                width: "30px",
                background: "#151814",
                padding: "10px",
                borderRadius: "50%",
              }}
              src="/images/twitter.svg"
              alt="X"
            />
          </a>
          <a
            href={SOCIAL_MEDIA_LINKS.Telegram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{
                height: "30px",
                width: "30px",
                background: "#151814",
                padding: "10px",
                borderRadius: "50%",
              }}
              src="/images/telegram.svg"
              alt="Telegram"
            />
          </a>
          <a
            href={SOCIAL_MEDIA_LINKS.Docs}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{ height: "50px", width: "50px" }}
              src="/images/docsIcon.svg"
              alt="Docs"
            />
          </a>
        </div>
      </div>
    </div>
    // <div className={styles.accessRestrictionOverlay}>
    //   <div className={styles.accessRestrictionModal}>
    //     <img src="/images/yaiLogo.svg" alt="YAI Logo" className={styles.logo} />
    //     <h2>Access Restricted</h2>
    //     <p>
    //       You need at least{" "}
    //       <span className={styles.highlight}>
    //         {MinTokenAmountLayout.toLocaleString()}
    //       </span>{" "}
    //       YAI tokens to access the DAO portal.
    //     </p>
    //     <div className={styles.actions}>
    //       <button
    //         className={styles.connectWalletButton}
    //         onClick={onConnectWallet}
    //       >
    //         Connect Wallet
    //       </button>
    //       <a
    //         href="https://app.uniswap.org"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //         className={styles.getTokensButton}
    //       >
    //         Get YAI Tokens
    //       </a>
    //     </div>
    //   </div>
    // </div>
  );
};

export default AccessRestriction;
