import React, { createContext, useContext } from 'react';
import { useMachine } from '@xstate/react';
import { fromPromise } from 'xstate';
import { useErrorContext } from '../context/ErrorContext';
import { referralMachine } from '../xstate/referralXstate';

// Create the context
export const ReferralContext = createContext(null);

// Create a custom hook to use the referral context
export const useReferral = () => {
  const context = useContext(ReferralContext);
  if (!context) {
    throw new Error('useReferral must be used within a ReferralProvider');
  }
  return context;
};

function ReferralWrapper({ children }) {
  const { axiosInstance, setError } = useErrorContext();

  const [state, send, service] = useMachine(
    referralMachine.provide({
      actors: {
        createReferralCode: fromPromise(async () => {
          try {
            const response = await axiosInstance.post("/generate_ref_code");
            return response?.data || null;
          } catch (error) {
            setError(error.message || "Failed to create referral code");
            throw new Error(error.message || "Failed to create referral code");
          }
        }),
        validateReferralCode: fromPromise(async ({ input }) => {
          try {
            const response = await axiosInstance.post("/validate_ref_code", {
              ref_code: input.code,
            });
            return response?.data || null;
          } catch (error) {
            setError(error.message || "Failed to validate referral code");
            throw new Error(
              error.message || "Failed to validate referral code"
            );
          }
        }),
        getReferralStats: fromPromise(async () => {
          try {
            const response = await axiosInstance.get("/get_ref_stats");
            return response?.data || null;
          } catch (error) {
            setError(error.message || "Failed to get referral stats");
            throw new Error(error.message || "Failed to get referral stats");
          }
        }),
        getUserAppliedReferralCode: fromPromise(async () => {
          try {
            const response = await axiosInstance.get("/get_applied_referral");
            return response?.data || null;
          } catch (error) {
            setError(error.message || "Failed to get user applied referral code");
            throw new Error(error.message || "Failed to get user applied referral code");
          }
        }),
        deleteReferralCode: fromPromise(async ({ input : { context }}) => {
          try {
            if (!context.referralCode) {
              throw new Error("No referral code to delete");
            }

            const response = await axiosInstance.delete(`/delete_ref_code/${context.referralCode}`);

            if (!response.data) {
              throw new Error("Failed to delete referral code");
            }

            return response.data;
          } catch (error) {
            const errorMessage = error.response?.data?.detail || 
                               error.message || 
                               "Failed to delete referral code";
            setError(errorMessage);
            throw new Error(errorMessage);
          }
        }),
        removeAppliedReferral: fromPromise(async () => {
          try {
            const response = await axiosInstance.delete('/remove_applied_referral');
            
            if (!response.data) {
              throw new Error("Failed to remove applied referral code");
            }

            return response.data;
          } catch (error) {
            const errorMessage = error.response?.data?.detail || 
                               error.message || 
                               "Failed to remove applied referral code";
            setError(errorMessage);
            throw new Error(errorMessage);
          }
        }),
      },
    })
  );

  return (
    <ReferralContext.Provider value={{ state, send, service }}>
      {children}
    </ReferralContext.Provider>
  );
}

export default ReferralWrapper;
