import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import AuthWrapper from "./AuthWrapper/AuthWrapper";
import { ModelProvider } from "./ContextProvider";
import { WalletProvider } from "./context/WalletContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./toastStyles.css";
import { ErrorProvider } from "./context/ErrorContext";
import DashboardLayout from "./components/withLayout/withLayout";
import ReferralWrapper from "./AuthWrapper/RefferalWrapper";
import ChatBoxProvider from "./context/chatbotContext";
import DaoProvider from "./context/DaoContext";
import { routeConfig, getFilteredRoutes } from "./config/routeConfig";

function AppRoutes() {
  const getComponent = (routeKey) => {
    const filteredRoutes = getFilteredRoutes(routeConfig);
    const route = filteredRoutes[routeKey];
    if (!route.enabled) return null;
    const Component = route.component;
    return <Component />;
  };

  return (
    <ErrorProvider>
      <WalletProvider>
        <ModelProvider>
          <Router>
            <ChatBoxProvider>
              <DaoProvider>
                <ReferralWrapper>
                  <AuthWrapper>
                    <Routes>
                      <Route element={<DashboardLayout />}>
                        {Object.entries(getFilteredRoutes(routeConfig)).map(
                          ([key, route]) =>
                            route.enabled && (
                              <Route
                                key={route.path}
                                path={route.path}
                                element={getComponent(key)}
                              />
                            )
                        )}
                        {/* Catch-all route */}
                        <Route path="*" element={<Navigate to="/" replace />} />
                      </Route>
                    </Routes>

                    <ToastContainer
                      className="custom-toast"
                      progressClassName="custom-progress-bar"
                    />
                  </AuthWrapper>
                </ReferralWrapper>
              </DaoProvider>
            </ChatBoxProvider>
          </Router>
        </ModelProvider>
      </WalletProvider>
    </ErrorProvider>
  );
}

export default AppRoutes;
