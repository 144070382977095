import React, { useState, useEffect, useContext, useMemo } from "react";
import CustomGauge from "./Gauge.js";
import styles from "./Technicals.module.css";
import Tooltip from "../Tooltip/Tooltip";
import { AuthContext } from "../../AuthWrapper/AuthWrapper.js";
import { extractProjectIds } from "../../utils/helpers.js";
import Dropdown from "../Dropdown/Dropdown";
const Technicals = React.memo(({ dashboardState }) => {
  const initialSelectedProjectId = useMemo(() => {
    if (!dashboardState) return null;
    const { uniqueProjectIds, tf } = extractProjectIds(dashboardState);
    return uniqueProjectIds.length > 0 ? uniqueProjectIds[0] : null;
  }, [dashboardState]);

  const [isOpen, setIsOpen] = useState(false);
  const [isTfOpen, setIsTfOpen] = useState(false);
  const [selectedTf, setSelectedTf] = useState("high");

  const { state, send } = useContext(AuthContext);
  const [cachedResiduals, setCachedResiduals] = useState({});

  useEffect(() => {
    if (!dashboardState || !state.context?.modelState) return;
    const selectedProjectId =
      state?.context?.selectedIds?.technical ?? initialSelectedProjectId;
    const projectData = state.context.modelState["oracle"]?.[selectedProjectId];
    if (!projectData) return;

    const timeframeData = projectData[selectedTf];
    if (!timeframeData) return;

    if (
      timeframeData.config &&
      timeframeData.config !== cachedResiduals.config
    ) {
      setCachedResiduals((prev) => ({
        ...prev,
        config: timeframeData.config,
      }));
    }
  }, [
    state.context?.modelState,
    state?.context?.selectedIds?.technical,
    selectedTf,
    initialSelectedProjectId,
  ]);

  const residuals = useMemo(() => {
    if (!dashboardState || !state.context?.modelState) return {};

    const selectedProjectId =
      state?.context?.selectedIds?.technical ?? initialSelectedProjectId;
    const projectData = state.context.modelState["oracle"]?.[selectedProjectId];
    if (!projectData) return {};

    const timeframeData = projectData[selectedTf];
    if (!timeframeData) return {};

    return {
      ...timeframeData,
      config: timeframeData.config || cachedResiduals.config,
    };
  }, [
    dashboardState,
    state.context?.modelState,
    state?.context?.selectedIds?.technical,
    selectedTf,
    initialSelectedProjectId,
    cachedResiduals.config,
  ]);

  useEffect(() => {
    if (dashboardState && state.matches("home")) {
      console.log(state.context.modelState, "state.context.modelState");

      const { uniqueProjectIds, tf } = extractProjectIds(dashboardState);
      send({
        type: "UPDATE_MODEL_AND_PROJECT",
        payload: {
          type: "technical",
          projectId: uniqueProjectIds[0],
        },
      });
    }
  }, [dashboardState, state.value]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    // setIsTfOpen(false);
  };

  const toggleTfDropdown = () => {
    setIsTfOpen(!isTfOpen);
    // setIsOpen(false);
  };

  const selectedIds = (projectId) => {
    send({
      type: "UPDATE_MODEL_AND_PROJECT",
      payload: {
        type: "technical",
        projectId,
      },
    });
    setIsOpen(false);
  };

  const selectTimeframe = (tf) => {
    send({
      type: "UPDATE_TIMEFRAME",
      payload: tf,
    });
    setSelectedTf(tf);

    setIsTfOpen(false);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.topContainer}>
        <div className={styles.headingContainer}>
          <div className={styles.heading}>Technicals</div>
          <Tooltip id="technicals" page="home-page" />
        </div>
        <div className={styles.dropdownsContainer}>
          <Dropdown
            selectedOption={
              state?.context?.selectedIds?.technical ?? initialSelectedProjectId
            }
            options={state.context.projectIds.uniqueProjectIds}
            onSelect={(option) => selectedIds(option)}
            isOpen={isOpen}
            toggleDropdown={toggleDropdown}
          />
          {/* <div className={styles.dropdownContainer}>
            <div className={styles.dropdown} onClick={toggleDropdown}>
              <span className={styles.selectedOption}>
                {state?.context?.selectedIds?.technical ??
                  initialSelectedProjectId}
              </span>
              <span className={styles.arrow}>
                {isOpen ? (
                  <img
                    className={styles.invertedArrow}
                    src="/images/arrowDown.svg"
                    alt="arrow down"
                  />
                ) : (
                  <img src="/images/arrowDown.svg" alt="arrow down" />
                )}
              </span>
            </div>
            {isOpen && (
              <div className={styles.dropdownMenu}>
                {state.context.projectIds.uniqueProjectIds.map((projectId) => (
                  <div
                    key={projectId}
                    className={styles.dropdownItem}
                    onClick={() => selectedIds(projectId)}
                  >
                    {projectId}
                  </div>
                ))}
              </div>
            )}
          </div> */}

          <Dropdown
            selectedOption={selectedTf}
            options={state.context.projectIds.tf}
            onSelect={(option) => selectTimeframe(option)}
            isOpen={isTfOpen}
            toggleDropdown={toggleTfDropdown}
          />
          {/* <div className={styles.dropdownContainer}>
            <div className={styles.dropdown} onClick={toggleTfDropdown}>
              <span className={styles.selectedOption}>{selectedTf}</span>
              <span className={styles.arrow}>
                {isTfOpen ? (
                  <img
                    className={styles.invertedArrow}
                    src="/images/arrowDown.svg"
                    alt="arrow down"
                  />
                ) : (
                  <img src="/images/arrowDown.svg" alt="arrow down" />
                )}
              </span>
            </div>
            {isTfOpen && (
              <div className={styles.dropdownMenu}>
                {state.context.projectIds.tf.map((tf) => (
                  <div
                    key={tf}
                    className={styles.dropdownItem}
                    onClick={() => selectTimeframe(tf)}
                  >
                    {tf}
                  </div>
                ))}
              </div>
            )}
          </div> */}
        </div>
      </div>
      <div className={styles.gaugeContainer}>
        <img
          className={styles.mask}
          src="/images/technicalsMask.svg"
          alt="mask"
        />
        <CustomGauge residual={residuals} />
      </div>
    </div>
  );
});

export default Technicals;
