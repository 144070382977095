import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  memo,
  useMemo,
} from "react";
import { AuthContext } from "../AuthWrapper/AuthWrapper.js";
import withAuthenticationGuard from "../components/guards/withAuthWrapper";
import HistoricalOverview from "../components/HistoricalOverview/HistoricalOverview";
import PerformanceHeatmap from "../components/PerformanceHeatmap/PerformanceHeatmap";
import StrategyOverview from "../components/StrategyOverview/StrategyOverview";
import Trades from "../components/Trades/Trades";
import {
  DEFAULT_MODEL,
  AMOUNT_NEEDED_FOR_TEMPORARY_SUBSCRIPTION,
} from "../constants/constants.js";
import { useChatBoxContext } from "../context/chatbotContext";
import Loader from "../components/Loader/Loader";

const Strategy = ({ fullWidth }) => {
  // const [performanceData, setPerformanceData] = useState(null);

  const { isConnected } = useWeb3ModalAccount();
  const { state, send } = useContext(AuthContext);
  const { state: chatbotState } = useChatBoxContext();
  const isLoading =
    state.matches("idle") ||
    state.matches("signingIn") ||
    state.matches("waitingForSubscription") ||
    state.matches("verifyingTransaction") ||
    state.matches("loggingOut") ||
    state.matches("loadingDashboardState") ||
    chatbotState.matches("fetchingSubscriptions");

  // const handleDataFetched = useCallback((data) => {
  //   setPerformanceData(data);
  // }, []);

  useEffect(() => {
    if (
      state.matches("model") ||
      state.matches("home") ||
      state.matches("strategy") ||
      state.matches("apiKey") ||
      state.matches("chat") ||
      state.matches("dao")
    )
      send({ type: "CHECK_ROUTE" });
  }, [send]);

  const RenderLoadingOverlay = () => <div className="loading-overlay" />;

  const handleModelProjectChange = useCallback(
    (model, projectId, strategyStateFilter, clearStrategyHistory = false) => {
      if (state.matches("strategy.idle")) {
        send({
          type: "UPDATE_MODEL_AND_PROJECT",
          payload: {
            type: "strategy",
            strategyStateFilter: {
              ...strategyStateFilter,
            },
            model,
            projectId,
          },
        });
      }
    },
    [state.value]
  );

  // this is to make initianl model oracle

  useEffect(() => {
    send({
      type: "UPDATE_MODEL_AND_PROJECT",
      payload: {
        model: DEFAULT_MODEL,
      },
    });
  }, [send]);

  const RenderLoader = memo(() => <Loader />);

  const renderComponents = (data) => (
    <>
      <HistoricalOverview
        // onDataFetched={data ? handleDataFetched : null}
        key={fullWidth}
        selectedModel={data ? state?.context?.selectedHistoricalModel : null}
        selectedProjectId={data ? state.context?.selectedIds?.strategy : null}
        onModelProjectChange={data ? handleModelProjectChange : null}
        dashboardState={data ? state.context.dashboardState : null}
        // onStrategyFilterChange={data ?  handleStrategyFilterChange : null }
      />
      <StrategyOverview
        // performanceData={data ? performanceData : null}
        selectedModel={data ? state?.context?.selectedHistoricalModel : null}
        selectedProjectId={data ? state.context?.selectedIds?.strategy : null}
        dashboardState={data ? state.context.dashboardState : null}
      />
      <Trades
        dashboardState={data ? state.context.dashboardState : null}
        selectedModel={data ? state?.context?.selectedHistoricalModel : null}
        selectedProjectId={data ? state.context?.selectedIds?.strategy : null}
      />
      <PerformanceHeatmap
      // dashboardState={data ? state.context.dashboardState : null}
      // selectedModel={data ? state?.context?.selectedHistoricalModel : null}
      // selectedProjectId={data ? state.context?.selectedIds?.strategy : null}
      />
    </>
  );
  // Extract the access check into a variable
  const hasValidAccess = useMemo(() => {
    // First, verify all required objects exist
    if (!isConnected) return false;
    if (!chatbotState?.context?.subscription) return false;

    const subscription = chatbotState.context.subscription;
    const expiryDate = subscription.expiry_date;
    const balance = subscription.balance || 0;

    // Now check subscription validity
    const hasValidExpiry =
      expiryDate && new Date(expiryDate).getTime() > new Date().getTime();
    const hasValidBalance = balance >= AMOUNT_NEEDED_FOR_TEMPORARY_SUBSCRIPTION;

    return hasValidExpiry || hasValidBalance;
  }, [isConnected, chatbotState?.context?.subscription]);

  return (
    <>
      {hasValidAccess && renderComponents(state.context.dashboardState)}
      {isLoading && <RenderLoadingOverlay />}
      {isLoading && <RenderLoader />}
    </>
  );
};

export default withAuthenticationGuard(Strategy);
