import React, { useContext, useEffect, useState } from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import ConnectModal from "../ConnectModal/ConnectModal";
import { useErrorContext } from "../../context/ErrorContext";
import SubscriptionModal from "../SubscriptionModal/SubscriptionModal";
import InsufficientBalanceModal from "../InsufficientBalanceModal/InsufficientBalanceModal";
import { AuthContext } from "../../AuthWrapper/AuthWrapper";
import {
  AMOUNT_NEEDED_FOR_TEMPORARY_SUBSCRIPTION,
  MinTokenAmountLayout,
} from "../../constants/constants";
import { useChatBoxContext } from "../../context/chatbotContext";
import AccessRestriction from "../AccessRestriction/AccessRestriction";
import { useLocation } from "react-router-dom";

const withAuthenticationGuard = (WrappedComponent) => {
  return function WithAuthenticationGuard(props) {
    const { isConnected } = useWeb3ModalAccount();
    const { state } = useContext(AuthContext);
    const { state: chatbotState } = useChatBoxContext();
    const location = useLocation();
    const isDaoPage = location.pathname === "/dao";
    const subscription = chatbotState.context.subscription;
    const expiryDate = subscription?.expiry_date;
    const balance = subscription?.balance || 0;
    const hasValidBalance = balance >= AMOUNT_NEEDED_FOR_TEMPORARY_SUBSCRIPTION;
    const hasValidExpiry =
      expiryDate && new Date(expiryDate).getTime() > new Date().getTime();

    const hasValidBalanceForDao = balance >= MinTokenAmountLayout;

    if (
      (!isConnected && state.matches("idle")) ||
      state.matches("signingIn") ||
      state.matches("loadingDashboardState")
    ) {
      return (
        <>
          <ConnectModal />
          <WrappedComponent {...props} />
        </>
      );
    } else if (
      !isDaoPage &&
      (state.matches("waitingForSubscription") ||
        state.matches("verifyingTransaction")) &&
      isConnected &&
      !hasValidBalance &&
      !hasValidExpiry
    ) {
      return (
        <>
          <InsufficientBalanceModal />
          <WrappedComponent {...props} />
        </>
      );
    }

    if (!hasValidBalanceForDao && isDaoPage) {
      return (
        <>
          <AccessRestriction />
          <WrappedComponent {...props} />
        </>
      );
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAuthenticationGuard;
