import React, { useEffect } from "react";
import "../components/ChatComponents/Chat.css";
import ChatInterface from "../components/ChatComponents/ChatInterface";
import PlotComponent from "../components/ChatComponents/PlotComponent";
import TableComponent from "../components/ChatComponents/TableComponent";
import Loader from "../components/Loader/Loader";
import { useChatBoxContext } from "../context/chatbotContext";
import withChatAuthWrapper from "../components/guards/withChatAuthWrapper";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { useContext } from "react";
import { AuthContext } from "../AuthWrapper/AuthWrapper";

const Chat = () => {
  const { state, send } = useContext(AuthContext);
  const { state: chatState } = useChatBoxContext();
  const isDetailsLoading =
    chatState.matches("processingMessage") ||
    chatState.matches("processingQuickAsk") ||
    chatState.matches("fetchingMessageDetails");

  const { isConnected } = useWeb3ModalAccount();

  useEffect(() => {
    if (
      state.matches("model") ||
      state.matches("strategy") ||
      state.matches("apiKey") ||
      state.matches("idle") ||
      state.matches("home") ||
      state.matches("dao")
    )
      send({ type: "CHECK_ROUTE" });
  }, [send]);

  const RenderLoadingOverlay = () => <div className="loading-overlay" />;

  return (
    <div
      className="LLM-view"
      style={{
        backgroundImage: 'url("/images/background.svg")',
        width: "100%",
      }}
    >
      {!isConnected && <RenderLoadingOverlay />}

      <div className="app-container" style={{ width: "100%" }}>
        <div className="chat-container">
          <ChatInterface isLoading={isDetailsLoading} disabled={!isConnected} />
        </div>
        <div className="data-visualization">
          <div className="plot-container">
            {isDetailsLoading && <Loader addOverLayShadow />}
            <PlotComponent fig={chatState.context?.currentResponse?.fig} />
          </div>
          <div className="table-container">
            {isDetailsLoading && <Loader addOverLayShadow />}
            <TableComponent
              data={
                chatState.context?.currentResponse || {
                  data: null,
                  fig: null,
                  type: null,
                }
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withChatAuthWrapper(Chat);
