import { VAULT_URL } from "../constants/constants"; // Adjust the import path as necessary
import Docs from "../pages/Docs";
import Home from "../pages/Home";
import Model from "../pages/Model";
import Strategy from "../pages/Strategy";
import Access from "../pages/Access";
import Dao from "../pages/Dao";
import Chat from "../pages/Chat";

export const routeConfig = {
  home: {
    path: "/",
    enabled: true,
    component: Home,
    comingSoon: false,
    icon: "/images/homeIcon.svg",
  },
  chat: {
    path: "/chat",
    enabled: true,
    component: Chat,
    comingSoon: false,
    icon: "/images/chatIcon.svg",
  },
  model: {
    path: "/model",
    enabled: true,
    component: Model,
    comingSoon: false,
    icon: "/images/modelIcon.svg",
  },
  strategy: {
    path: "/strategy",
    enabled: true,
    component: Strategy,
    comingSoon: false,
    icon: "/images/strategyIcon.svg",
  },
  access: {
    path: "/access",
    enabled: true,
    component: Access,
    comingSoon: false,
    icon: "/images/apiIcon.svg",
  },
  docs: {
    path: "/docs",
    enabled: true,
    component: Docs,
    comingSoon: false,
    icon: "/images/docsIcon.svg",
  },
  vault: {
    path: VAULT_URL,
    enabled: true,
    component: "", // Assuming ExternalLink handles external URLs
    comingSoon: false,
    isExternal: true,
    icon: "/images/vaultIcon.svg", // Ensure you have an appropriate icon for Vault
  },
  guide: {
    path: "https://www.youtube.com/@YAIoracle",
    enabled: true,
    component: "", // Assuming ExternalLink handles external URLs
    comingSoon: false,
    isExternal: true,
    icon: "/images/youtube.svg",
  },
  dao: {
    path: "/dao",
    enabled: false,
    component: Dao,
    comingSoon: true,
    icon: "/images/strategyIcon.svg",
  },
};

export const getFilteredRoutes = (routes) => {
  return Object.entries(routes)
    .filter(([_, route]) => {
      // Check if the route is internal and has a component
      const hasComponentAndInternal = !route.isExternal && route.component;

      // Check if the route is either enabled or marked as coming soon
      const isEnabledOrComingSoon = route.enabled;
      // Combine the conditions: must satisfy both to be included
      return hasComponentAndInternal && isEnabledOrComingSoon;
    })
    .reduce((acc, [key, route]) => {
      acc[key] = route;
      return acc;
    }, {});
};

export const getSidebarMenu = (routes) => {
  return Object.entries(routes)
    .filter(([_, route]) => route.enabled || route.comingSoon)
    .reduce((acc, [key, route]) => {
      acc[key] = route;
      return acc;
    }, {});
};
