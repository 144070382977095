import React from 'react';
import styles from './avatar.module.css';

const Avatar = ({ image }) => {
  return (
    <span className={styles.avatar}>
      {' '}
      <img src={image} alt='AI' className={styles.avatarLogo} />{' '}
    </span>
  );
};

export default Avatar;
