import React, { useContext } from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import ConnectModal from "../ConnectModal/ConnectModal";
import { AuthContext } from "../../AuthWrapper/AuthWrapper";
import { useErrorContext } from "../../context/ErrorContext";
import LimitExceededModal from "../LimitExceedModal/LimitExceedModal";

const withChatAuthWrapper = (WrappedComponent) => {
  return function WithChatAuthWrapper(props) {
    const { isConnected } = useWeb3ModalAccount();
    const { state } = useContext(AuthContext);
    const { isLimitExceeded } = useErrorContext();

    if ((!isConnected && state.matches("idle")) || state.matches("signingIn")) {
      return (
        <>
          <ConnectModal hideSubscriptionMessage={true} />
          <WrappedComponent {...props} />
        </>
      );
    }
    if (isLimitExceeded) {
      return (
        <>
          <LimitExceededModal />
          <WrappedComponent {...props} />
        </>
      );
    }
    return <WrappedComponent {...props} />;
  };
};
export default withChatAuthWrapper;
